import './Iskustva.scss'
import "@fontsource/noto-sans";
import { Stars } from '../Background/Stars';
import { Fragment, useState } from 'react';
import IskustvaFullScreen from './IskustvaFullScreen';
import Media from 'react-media';
import matchers from '@testing-library/jest-dom/matchers';
import IskustvaMobile from './IskustvaMobile';
import { GestureDetector } from 'onsenui';
import HeaderSazvezdje from '../Header/HeaderSazvezdje';

const Iskustva = () => {
    var slike = [{
      "pitanja_i_odgovori": [
        {
          "pitanje": "Ukratko se predstavi.",
          "odgovor": "Zdravo svima, moje ime je Predrag. Član sam FONIS-a i prošlogodišnji takmičar na FON Hakatonu. Trenutno sam treća godina studija na Fakultetu organizacionih nauka."
        },
        {
          "pitanje": "Zašto si odlučio da se prijaviš za FON Hakaton?",
          "odgovor": "Već duže vreme sam želeo da se prijavim za neko takmičenje poput ovog. Izgledalo mi je veoma intrigantno takmičenje - 24 časa na fakultetu, razvijanje softverskog rešenja i samim tim nije bilo dileme da se prijavim. S obzirom na to da sam član Udruženja koje je organizator FON Hakatona, iz prve ruke znam koliko profesionalno FONIS pristupa organizaciji ovakvog takmičenja i hteo sam da prvi hakaton na kome učestvujem bude upravo ono koje je visokog kvaliteta."
        },
        {
          "pitanje": "Koje softversko rešenje je tvoj tim kreirao na hakatonu?",
          "odgovor": "Tema prošlogodišnjeg hakatona je bila Well-being. Rešenje koje smo mi kreirali nazvali smo Emotional Diary. Aplikacija funkcioniše tako što korisnik unosi u dnevnik svakog dana svoje misli (poput klasičnog dnevnika) i na osnovu unosa u dnevnik šalje se prompt chatgpt 3.5 modelu da na skali od 1 do 5 oceni emocionalno stanje korisnika i kada stigne rezultat od modela u aplikaciji se upiše ocena. Istorija svih ocena kao i unosa teksta u dnevnik koji je ocenjen može da se vidi na aplikaciji. Generalno smo bili zadovoljni onim što smo implementirali i mislimo da smo dobro odgovorili na zadatu temu hakatona."
        },
        {
          "pitanje": "Kako ste formirali tim?",
          "odgovor": "Tim je veoma spontano nastao. Pozvao sam druga koji živi isto u Beogradu i pitao ga da li želi da se sa mnom takmiči na FON Hakatonu. Pozvao je još dvojicu svojih drugara i mi smo se zapravo svi zajedno upoznali na samom takmičenju. Nismo se pre finala uživo nijednom videli ali sam baš bio zadovoljan samim timom, odlično smo funkcionisali uprkos prethodnom nepoznanstvu, odlično smo se slagali i iz moje perspektive je bilo baš super raditi sa ostalim članovima tima."
        },
        {
          "pitanje": "Šta bi preporučio nekom ko želi da se prijavi za FON Hakaton 2024?",
          "odgovor": "Prijavite se jer je ovo jedno nezaboravno iskustvo, a kada stignete do finala, ostavite po strani takmičarski aspekt. On jeste bitan ali treba ga staviti na drugom mestu. Na prvom mestu treba da bude dobar provod i stvaranje lepih uspomena."
        }
      ]
    }
    ,
    {
        "pitanja_i_odgovori": [
          {
            "pitanje": "Ukratko se predstavi.",
            "odgovor": "Zdravo, moje ime je Aleksa Milić. Trenutno sam druga godina studija na Računarskom fakultetu. Prošle godine sam imao čast da sa svojim timom osvojim treće mesto na FON Hakatonu, što je bilo izuzetno iskustvo za mene."
          },
          {
            "pitanje": "Zašto si odlučio/la da se prijaviš za FON Hakaton?",
            "odgovor": "Odlučio sam se da se prijavim za FON Hakaton iz nekoliko razloga. Prvo, želeo sam da steknem praktično iskustvo u radu na stvarnim projektnim zadacima, što je hakaton pružio. Takođe, bio je to sjajan način da se povežem sa kolegama iz industrije i drugim studentima, da razmenim ideje i steknem nova poznanstva. Takođe, volim izazove i rad pod pritiskom, pa mi je hakaton pružio idealnu priliku da testiram svoje veštine u takvom okruženju."
          },
          {
            "pitanje": "Koja nova znanja i veštine si stekao/la na Hakatonu?",
            "odgovor": "Prvenstveno, poboljšao sam svoje veštine u timskom radu, brzom resavanju problema i efikasnom upravljanju vremenom. Takođe sam naučio kako da efikasno koristim određene tehnologije koje su nam bile potrebne za projekat."
          },
          {
            "pitanje": "Šta bi preporučio/la nekome ko bi želeo da se prijavi za FON Hakaton?",
            "odgovor": "Svima koji razmišljaju o prijavljivanju za hakaton, toplo bih preporučio da to učine. To je sjajna prilika da steknu nova znanja, upoznaju nove ljude, i stvarno testiraju svoje veštine u realnom okruženju."
          }
        ]
      }
      ,
      {
        "pitanja_i_odgovori": [
          {
            "pitanje": "Ukratko se predstavi.",
            "odgovor": "Ćao, ja sam Mateja, alumni član FONIS-a i bivši fonovac. Trenutno sam zaposlen kao Medior Fullstack developer u kompaniji Levi9 i do sada sam učestvovao 3 puta na FON Hakatonu i osvojio sam i prvo i drugo i treće mesto."
          },
          {
            "pitanje": "Zašto si odlučio da se prijaviš za FON Hakaton?",
            "odgovor": "Za FON Hakaton sam se prvi put prijavio u trećoj godini fakulteta jer sam želeo da vidim koliko zapravo znam, i da li sam spreman da za jako kratko vreme napravim funkcionalnu aplikaciju."
          },
          {
            "pitanje": "Sa kojim izazovima se tvoj tim suočio u finalu?",
            "odgovor": "Timski rad je nešto čemu treba posvetiti dosta pažnje tokom hakatona jer podela posla za tako kratko vreme može biti nezgodna. Ono što se nama dešavalo je da neko legne da odmori sat vremena i kad se probudi, ono što je uradio više ne funkcioniše, jer nismo dobro iskomunicirali koji deo je kako urađen."
          },
          {
            "pitanje": "Koja ti je najlepša uspomena sa FON Hakatona?",
            "odgovor": "Najlepša uspomena mi je definitivno naša prezentacija na prvom FON Hakatonu. Dok sam ja prezentovao aplikaciju, dva člana mog tima su bili u kolima i snimali i prezentovali kako se aplikacija ponaša u toku vožnje kola, kako meri brzinu i pamti lokaciju telefona. Reakcije žirija su bile fenomenalne i tada smo osvojili prvo mesto!"
          },
          {
            "pitanje": "U kojoj meri je FON Hakaton doprineo razvoju tvoje karijere?",
            "odgovor": "Mislim da su hakatoni bili jako bitan deo mog učenja, jer sam se tokom njih susreo sa toliko problema i bugova za tako kratko vreme i morao da naučim da ih rešim isti taj dan. Takođe, partner na mom poslednjem FON Hakatonu je bila kompanija Levi9, koja me je posle hakatona kontaktirala za otvorenu poziciju i tako sam i dobio svoj prvi posao."
          }
        ]
      }
      ];
    var slikeMobilnaVerzija = [{
      "pitanja_i_odgovori": [
        {
          "pitanje": "Ukratko se predstavi.",
          "odgovor": "Zdravo svima, moje ime je Predrag. Član sam FONIS-a i prošlogodišnji takmičar na FON Hakatonu. Trenutno sam treća godina studija na Fakultetu organizacionih nauka."
        },
        {
          "pitanje": "Zašto si odlučio da se prijaviš za FON Hakaton?",
          "odgovor": "Već duže vreme sam želeo da se prijavim za neko takmičenje poput ovog. Izgledalo mi je veoma intrigantno takmičenje - 24 časa na fakultetu, razvijanje softverskog rešenja i samim tim nije bilo dileme da se prijavim. S obzirom na to da sam član Udruženja koje je organizator FON Hakatona, iz prve ruke znam koliko profesionalno FONIS pristupa organizaciji ovakvog takmičenja i hteo sam da prvi hakaton na kome učestvujem bude upravo ono koje je visokog kvaliteta."
        },
        {
          "pitanje": "Koje softversko rešenje je tvoj tim kreirao na hakatonu?",
          "odgovor": "Tema prošlogodišnjeg hakatona je bila Well-being. Rešenje koje smo mi kreirali nazvali smo Emotional Diary. Aplikacija funkcioniše tako što korisnik unosi u dnevnik svakog dana svoje misli (poput klasičnog dnevnika) i na osnovu unosa u dnevnik šalje se prompt chatgpt 3.5 modelu da na skali od 1 do 5 oceni emocionalno stanje korisnika i kada stigne rezultat od modela u aplikaciji se upiše ocena. Istorija svih ocena kao i unosa teksta u dnevnik koji je ocenjen može da se vidi na aplikaciji. Generalno smo bili zadovoljni onim što smo implementirali i mislimo da smo dobro odgovorili na zadatu temu hakatona."
        },
        {
          "pitanje": "Kako ste formirali tim?",
          "odgovor": "Tim je veoma spontano nastao. Pozvao sam druga koji živi isto u Beogradu i pitao ga da li želi da se sa mnom takmiči na FON Hakatonu. Pozvao je još dvojicu svojih drugara i mi smo se zapravo svi zajedno upoznali na samom takmičenju. Nismo se pre finala uživo nijednom videli ali sam baš bio zadovoljan samim timom, odlično smo funkcionisali uprkos prethodnom nepoznanstvu, odlično smo se slagali i iz moje perspektive je bilo baš super raditi sa ostalim članovima tima."
        },
        {
          "pitanje": "Šta bi preporučio nekom ko želi da se prijavi za FON Hakaton 2024?",
          "odgovor": "Prijavite se jer je ovo jedno nezaboravno iskustvo, a kada stignete do finala, ostavite po strani takmičarski aspekt. On jeste bitan ali treba ga staviti na drugom mestu. Na prvom mestu treba da bude dobar provod i stvaranje lepih uspomena."
        }
      ]
    }
    ,
    {
        "pitanja_i_odgovori": [
          {
            "pitanje": "Ukratko se predstavi.",
            "odgovor": "Zdravo, moje ime je Aleksa Milić. Trenutno sam druga godina studija na Računarskom fakultetu. Prošle godine sam imao čast da sa svojim timom osvojim treće mesto na FON Hakatonu, što je bilo izuzetno iskustvo za mene."
          },
          {
            "pitanje": "Zašto si odlučio/la da se prijaviš za FON Hakaton?",
            "odgovor": "Odlučio sam se da se prijavim za FON Hakaton iz nekoliko razloga. Prvo, želeo sam da steknem praktično iskustvo u radu na stvarnim projektnim zadacima, što je hakaton pružio. Takođe, bio je to sjajan način da se povežem sa kolegama iz industrije i drugim studentima, da razmenim ideje i steknem nova poznanstva. Takođe, volim izazove i rad pod pritiskom, pa mi je hakaton pružio idealnu priliku da testiram svoje veštine u takvom okruženju."
          },
          {
            "pitanje": "Koja nova znanja i veštine si stekao/la na Hakatonu?",
            "odgovor": "Prvenstveno, poboljšao sam svoje veštine u timskom radu, brzom resavanju problema i efikasnom upravljanju vremenom. Takođe sam naučio kako da efikasno koristim određene tehnologije koje su nam bile potrebne za projekat."
          },
          {
            "pitanje": "Šta bi preporučio/la nekome ko bi želeo da se prijavi za FON Hakaton?",
            "odgovor": "Svima koji razmišljaju o prijavljivanju za hakaton, toplo bih preporučio da to učine. To je sjajna prilika da steknu nova znanja, upoznaju nove ljude, i stvarno testiraju svoje veštine u realnom okruženju."
          }
        ]
      }
      ,
      {
        "pitanja_i_odgovori": [
          {
            "pitanje": "Ukratko se predstavi.",
            "odgovor": "Ćao, ja sam Mateja, alumni član FONIS-a i bivši fonovac. Trenutno sam zaposlen kao Medior Fullstack developer u kompaniji Levi9 i do sada sam učestvovao 3 puta na FON Hakatonu i osvojio sam i prvo i drugo i treće mesto."
          },
          {
            "pitanje": "Zašto si odlučio da se prijaviš za FON Hakaton?",
            "odgovor": "Za FON Hakaton sam se prvi put prijavio u trećoj godini fakulteta jer sam želeo da vidim koliko zapravo znam, i da li sam spreman da za jako kratko vreme napravim funkcionalnu aplikaciju."
          },
          {
            "pitanje": "Sa kojim izazovima se tvoj tim suočio u finalu?",
            "odgovor": "Timski rad je nešto čemu treba posvetiti dosta pažnje tokom hakatona jer podela posla za tako kratko vreme može biti nezgodna. Ono što se nama dešavalo je da neko legne da odmori sat vremena i kad se probudi, ono što je uradio više ne funkcioniše, jer nismo dobro iskomunicirali koji deo je kako urađen."
          },
          {
            "pitanje": "Koja ti je najlepša uspomena sa FON Hakatona?",
            "odgovor": "Najlepša uspomena mi je definitivno naša prezentacija na prvom FON Hakatonu. Dok sam ja prezentovao aplikaciju, dva člana mog tima su bili u kolima i snimali i prezentovali kako se aplikacija ponaša u toku vožnje kola, kako meri brzinu i pamti lokaciju telefona. Reakcije žirija su bile fenomenalne i tada smo osvojili prvo mesto!"
          },
          {
            "pitanje": "U kojoj meri je FON Hakaton doprineo razvoju tvoje karijere?",
            "odgovor": "Mislim da su hakatoni bili jako bitan deo mog učenja, jer sam se tokom njih susreo sa toliko problema i bugova za tako kratko vreme i morao da naučim da ih rešim isti taj dan. Takođe, partner na mom poslednjem FON Hakatonu je bila kompanija Levi9, koja me je posle hakatona kontaktirala za otvorenu poziciju i tako sam i dobio svoj prvi posao."
          }
        ]
      }
      
    ]
    var imena = ['Predrag Stojković','Aleksa Milić','Mateja Ivanović'];
    var timovi = ["Član tima Semicolon FON Hakaton 2023","Član tima RAF Orcas FON Hakaton 2023","Član tima It works on my machine FON Hakaton 2022"]
    var [brojac,setBrojac] = useState(0);
    var [slika, setSlika] = useState(slike[brojac]);
    var [ime,setIme] = useState(imena[brojac]);
    var [tim,setTim] = useState(timovi[brojac]);
   

    const levo = (imgs)=>{
        if(brojac != 0){
            setBrojac(--brojac);
            setIme(imena[brojac]);
            setSlika(imgs[brojac]);
            setTim(timovi[brojac]);

        }else {
            setIme(imena[imena.length-1]);
            setSlika(imgs[slike.length-1]);
            setBrojac(imena.length-1);
            setTim(timovi[timovi.length-1]);
        }
    }
    const desno = (imgs)=>{
        if(brojac != slike.length-1){
            setBrojac(++brojac);
            setIme(imena[brojac]);
            setSlika(imgs[brojac]);
            setTim(timovi[brojac]);

        }else {
            setIme(imena[0]);
            setTim(timovi[0]);
            setSlika(imgs[0]);
            setBrojac(0);
            
        }
    }

    return (
        <div>
            <HeaderSazvezdje bezlogoa={false}/>
            <Media queries = {{
                mobilna: "(max-width: 849px)",
                full_screen: "(min-width: 850px)"
            }}>
                {matchers => (
                    <Fragment>
                        {matchers.full_screen &&  <IskustvaFullScreen levo={()=>{levo(slike)}} desno = {()=>{desno(slike)}} ime={ime} tim={tim} slika={slike[brojac]} />}
                        {matchers.mobilna &&  <IskustvaMobile levo={()=>{levo(slikeMobilnaVerzija)}} desno = {()=>{desno(slikeMobilnaVerzija)}} ime={ime} tim={tim} slika={slikeMobilnaVerzija[brojac]}/>}
                    </Fragment>
                )}
            </Media>
        </div>
       
       
      );
}
 
export default Iskustva;