import React, { useContext } from 'react'
import { SwitchContextProvider } from '../../components/Switch/context/SwitchProvider'

/**
 * Ovo zapravo nije nagrada nego samo lebdeća nagrada, pls ne importuj ovo. Poseti [`Nagrade`](./Nagrade.jsx).
 * @param {Array} data niz od dva objekta
 * @returns tvoja keva
 */
const ZapravoNagrada = ({ data }) => {
    const [value] = useContext(SwitchContextProvider);

    return (
        <>
            <div className='nagrade-prvo nagrate-container-wrapper'>
                <div className='nagrade-container'>{value ? data[1].prvo_mesto : data[0].prvo_mesto}</div>
            </div>
            <div className='nagrade-drugo nagrate-container-wrapper'>
                <div className='nagrade-container'>{value ? data[1].drugo_mesto : data[0].drugo_mesto}</div>
            </div>
            <div className='nagrade-trece nagrate-container-wrapper'>
                <div className='nagrade-container'>{value ? data[1].trece_mesto : data[0].trece_mesto}</div>
            </div>
        </>
    )
}

export default ZapravoNagrada