import React, { useState, useEffect } from "react";
import Okvir from "./Okvir";
import strelica from "./Strelica.png";

const OHakatonuCard = () => {
  const hakaton = [
    "FON Hakaton je takmičenje koje pruža mladim programerima priliku da primene svoja znanja u rešavanju stvarnih poslovnih izazova. Timovi od <b>tri ili četiri člana</b> koji se plasiraju u finale imaju <b>24 sata</b> da razviju softversko rešenje na zadatu temu. Nakon toga, prezentuju svoj rad žiriju, boreći se za jednu od <b>tri glavne nagrade.</b>",
    "Na takmičenju će biti prisutni i <b>stručni mentori</b>, koji će pružati tehničku podršku i davati sugestije takmičarima prilikom izrade rešenja. Finale takmičenja se održava <b>30. i 31. marta na Fakultetu organizacionih nauka.</b>",
    "<b>Wargaming</b>, globalna kompanija u gaming industriji, pokrovitelj je ovogodišnjeg FON Hakatona i odrediće temu hakatona na otvaranju takmičenja.",
    "Ne propusti priliku! <a href='/prijava'>Prijavi se</a> do 16. marta.",
  ];
  const [tekstHakaton, setTekstHakaton] = useState(hakaton[0]);
  const [buttonH, setButtonH] = useState(false);
  // window.localStorage.setItem("textH", tekstHakaton);

  const handleTextChange = (forward) => {
    let index;
    if (forward) {
      index = hakaton.indexOf(tekstHakaton);
      if (index === hakaton.length - 1) setTekstHakaton(hakaton[0]);
      else setTekstHakaton(hakaton[index + 1]);
    } else {
      index = hakaton.indexOf(tekstHakaton);
      if (index === 0) setTekstHakaton(hakaton[hakaton.length - 1]);
      else setTekstHakaton(hakaton[index - 1]);
    }
  };

  useEffect(() => {
    setTekstHakaton(hakaton[0]);
    // setTekstHakaton(window.localStorage.getItem("textH"));
  
  }, []);

  // useEffect(() => {
  //   // window.localStorage.setItem("textH", tekstHakaton);
  //   // if (hakaton.indexOf(tekstHakaton) === hakaton.length - 1) setButtonH(true);
  //   // else setButtonH(false);
  // }, [tekstHakaton]);

  return (
    <div className="card">
      <h1>O Hakatonu</h1>
      <div className="card-buttons">
        <img
          src={strelica}
          className="druga"
          onClick={() => handleTextChange(false)}
        />
        <Okvir tekst={tekstHakaton} buttonH={buttonH} />
        <img src={strelica} onClick={() => handleTextChange(true)} />
      </div>
    </div>
  );
};

export default OHakatonuCard;
