import React, { useState } from 'react';
import './FaqPitanje.scss';


const FaqPitanje = ({question, answer}) => {

    const [shown, setShown] = useState(false);
    const [firstLoaded, setFirstLoaded] = useState(true);

    function Prikazi () {
        if (!shown) {
            setShown(true);
            setFirstLoaded(false);
        }
        else setShown(false);
    }

    return (
        <div className='question-div'>
            <div className='title-div' onClick={Prikazi}>
                <div className='title-arrow'>
                    <p>{question}</p>
                    <svg className='strelica' viewBox="22 20 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_400_8)">
                            <path d="M44.0914 28.4192L38.1075 32.1234L32.1236 28.4192L32.1236 25L38.1075 28.4192L44.0914 25L44.0914 28.4192Z" fill="url(#paint0_linear_400_8)"/>
                            <path d="M46.086 35.2576L38.1075 40.1015L30.414 35.2576L30.414 30.9836L38.1075 35.5426L46.086 30.9836L46.086 35.2576Z" fill="url(#paint1_linear_400_8)"/>
                            <path d="M51.5 43.2358L38.1075 52.9236L25 43.2358L25 35.2576L38.1075 44.3755L51.5 35.2576L51.5 43.2358Z" fill="url(#paint2_linear_400_8)"/>
                        </g>
                        <path id='strela1' d="M44.0914 28.4192L38.1075 32.1234L32.1236 28.4192L32.1236 25L38.1075 28.4192L44.0914 25L44.0914 28.4192Z" fill="url(#paint3_linear_400_8)"/>
                        <path id='strela2' d="M46.086 35.2576L38.1075 40.1015L30.414 35.2576L30.414 30.9836L38.1075 35.5426L46.086 30.9836L46.086 35.2576Z" fill="url(#paint4_linear_400_8)"/>
                        <path id='strela3' d="M51.5 43.2358L38.1075 52.9236L25 43.2358L25 35.2576L38.1075 44.3755L51.5 35.2576L51.5 43.2358Z" fill="url(#paint5_linear_400_8)"/>
                        <defs>
                        <filter id="filter0_f_400_8" x="0" y="0" width="76.5" height="77.9238" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_400_8"/>
                        </filter>
                        <linearGradient id="paint0_linear_400_8" x1="38.25" y1="52.9236" x2="38.25" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#24BDDE"/>
                        <stop offset="1" stopColor="#24BDDE" stopOpacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_400_8" x1="38.25" y1="52.9236" x2="38.25" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#24BDDE"/>
                        <stop offset="1" stopColor="#24BDDE" stopOpacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_400_8" x1="38.25" y1="52.9236" x2="38.25" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#24BDDE"/>
                        <stop offset="1" stopColor="#24BDDE" stopOpacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_400_8" x1="38.25" y1="52.9236" x2="38.25" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#24BDDE"/>
                        <stop offset="1" stopColor="#24BDDE" stopOpacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_400_8" x1="38.25" y1="52.9236" x2="38.25" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#24BDDE"/>
                        <stop offset="1" stopColor="#24BDDE" stopOpacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_400_8" x1="38.25" y1="52.9236" x2="38.25" y2="25" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#24BDDE"/>
                        <stop offset="1" stopColor="#24BDDE" stopOpacity="0"/>
                        </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className='svg-div'>
                <svg id='obicanSvg' width="100%" height="34" viewBox="0 0 970 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    
                    <path id='line' d="M0 12 L369.154 12
                    L366.79 12 L426.417 31.5
                    M425.631 31.5 L539.213 31.5
                    L538.372 31.5 L597.163 13
                    M596.313 12 L965.457 12"
                    stroke="#D9D9D9" strokeWidth="5"/>
                    <path id='elipse' d="M947.623 11.5 A11.1886 11.5 0 1 0 970 11.5 A11.1886 11.5 0 1 0 947.623 11.5" fill="#D9D9D9"/>

                </svg>

                <svg id="gradientSvg" width="100%" height="34" viewBox="0 0 970 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor='#7B2787'>
                            </stop>
                            <stop offset="100%" stopColor='#002440'>
                            </stop>
                        </linearGradient>
                    </defs>
                    
                    <path id='gradientLine' className={shown ? 'active' : firstLoaded ? '' : 'default'} d="M0 12 L369.154 12
                    366.79 12 L426.417 31.5
                    425.631 31.5 L539.213 31.5
                    538.372 31.5 L597.163 13
                    596.313 12 L965.457 12"
                    stroke="url(#grad1)" strokeWidth="5"/>
                    <path id='gradientElipse' className={shown ? 'active' : ''} d="M947.623 11.5 A11.1886 11.5 0 1 0 970 11.5 A11.1886 11.5 0 1 0 947.623 11.5" fill="#002440"/>

                </svg>
                </div>
            </div>
            <div className={`answer-holder ${shown ? "shown" : ""}`}>
                <div className={`answer-div`}>
                    <p className='answer'>
                        {answer}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FaqPitanje;