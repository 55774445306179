import React from 'react'
import HeaderLogoOnly from './HeaderLogoOnly';
import { useContext, useState, useEffect } from 'react';
import Header from './Header';
import Sazvezdje from '../NavigacioniMeni/Sazvezdje/Sazvezdje';
import './HeaderSazvezdje.scss'
import { HeaderContextProvider } from './context/HeaderProvider'

const HeaderSazvezdje = ({ imasv, bezlogoa }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [veliki, setVeliki] = useState(false);
  // const [veliki, setVeliki] = useContext(HeaderContextProvider);

  let nemalogo = (bezlogoa == undefined ? false : bezlogoa);

  const updateSize = () => {
    // console.log(window.matchMedia("max-width:1300px"))
    setVeliki(window.matchMedia("(max-width: 1300px)").matches ? false : true);
    //setOpenMenu(false);
  };
  useEffect(() => {
    // setTimeout(updateSize, 3700)
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => {
      // updateSize();
      window.removeEventListener('resize', updateSize);
    };
  }, []);


  const handleClick = () => {
    setOpenMenu(prev => !prev);
    // console.log(openMenu)
  }
  return (<>
    {veliki ? 
    <> 
      <Sazvezdje funkcija={updateSize} /> 
      {(imasv ? 
        (<Header openMenu={openMenu} handleClick={handleClick} bezlogoa={nemalogo} />) : 
        (<HeaderLogoOnly openMenu={openMenu} handleClick={handleClick} bezlogoa={nemalogo} />))}
    
    </>:
      <>
        {(imasv ? (<Header openMenu={openMenu} handleClick={handleClick} bezlogoa={nemalogo} />) : (<HeaderLogoOnly openMenu={openMenu} handleClick={handleClick} bezlogoa={nemalogo} />))}
        <div className={`pozadinabl ${openMenu ? 'blur' : ''}`}></div>
        <Sazvezdje className={`zvezdemeni ${openMenu ? 'otvoren' : ''}`} funkcija={updateSize} />
      </>}
  </>)
}

export default HeaderSazvezdje