import React, { memo, useContext, useEffect } from 'react'
import { SwitchContextProvider } from '../../../components/Switch/context/SwitchProvider';

const Block = memo(function Block({ data, onClick, onMount }) {
  const [value] = useContext(SwitchContextProvider);

  useEffect(() => {
    onMount(prev => ++prev);
    return () => {
      //console.log("unmounted");
      onMount(prev => --prev);
    }
  }, [onMount]);

  return (
    <div className='agenda-block-wrapper'>
      <div className='agenda-loptica' onClick={onClick}></div>
      <div className={`agenda-block ${value ? "agenda-block-bc" : ""}`} onClick={onClick}>
        <h1>{data.header}</h1>
        <p>{data.text}</p>
      </div>
    </div>
  )
});

export default Block