import './Partneri.scss'
import strateski from "../Partneri/images/strat.svg"
import medijski from "../Partneri/images/med.svg"
import naturalni from "../Partneri/images/nat.svg"
import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pokrovitelj from "./images/Wargaming.png"




const PartneriFullScreen = () => {
  const [parNatShow, setParNatShow]= useState(true)
  const [parStratShow, setParStratShow]= useState(false)
  const [parMedShow, setParMedShow]= useState(false)
  const [parShow, setParShow]= useState(true)
  const imagesNat = require.context('./images/naturalni', true);
  const imageNatList = imagesNat.keys().map(image => imagesNat(image));

  const imagesStrat = require.context('./images/strateski', true);
  const parStratSlike = imagesStrat.keys().map(image => imagesStrat(image));

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear"
    
  };

    return(

      <div className="Partneri">

        <div className = "par-parent">

          <h1 className='par-child' id='naslov'>Partneri</h1>
          
          <div className='par-child' id='pokrovitelj'>

            <h2 id='pokrovitelj-naslov'>Pokrovitelj takmičenja</h2>
            
            <div className='par-img-container' id='pokrovitelj-kutija'>
              <img src={pokrovitelj}  id='pokrovitelj-slika'/>
            </div>

          </div>

          <div className='par-child' id='par-btn-parent' style={{width:"30%"}}>
            <button className='par-btn-child' id='par-btn-strat' onClick={()=>{setParStratShow(true); setParNatShow(false); setParMedShow(false); setParShow(false);}}>
            <img src={strateski} className='par-btn-img' id='par-btn-img-god' alt='strateski partneri'>
            </img>
            </button>
            
            <button className='par-btn-child' id='par-btn-nat' onClick={()=>{setParStratShow(false); setParNatShow(true); setParMedShow(false); setParShow(false);}}>
            <img src={naturalni} className='par-btn-img' id='par-btn-img-nat' alt='naturalni partneri'>
            </img>
            </button>

            {/* <button className='par-btn-child' id='par-btn-med' onClick={()=>{setParStratShow(false); setParNatShow(false); setParMedShow(true); setParShow(false);}}>
            <img src={medijski} className='par-btn-img' id='par-btn-img-med' alt='medijski partneri'>
            </img>
            </button> */}
          </div>
          
          {/* slajder za sve */}
          {/* {parShow?<div className='par-child par-img-slider' id='par-img-parent'>
            <Slider {...settings}>
              
              <div className='par-img-child par-img-container' >
              <img src={require('../Partneri/images/bclogodark.png')} className='par-img'  alt="" />
              </div>
              
              <div className='par-img-child par-img-container' >
              <img src={require('../Partneri/images/floridabc.png')} className='par-img'  alt="" />
              </div>

              <div className='par-img-child par-img-container' >
              <img src={require('../Partneri/images/primesoftware.png')} className='par-img' id='par-florida' alt="" />
              </div>

              <div className='par-img-child par-img-container'>
              <img src={require('../Partneri/images/fon.png')} className='par-img'alt="" />
              </div>

              <div className='par-img-child par-img-container par-nat' >
              <img src={require('../Partneri/images/hrfabrika.png')} className='par-img' alt="" />
              </div>

              <div className='par-img-child par-img-container par-med'>
              <img src={require('../Partneri/images/img.png')} className='par-img' alt="" />
              </div>

              <div className='par-img-child par-img-container par-strat' >
              <img src={require('../Partneri/images/primesoftware.png')} className='par-img' alt="" />
              </div>

              <div className='par-img-child par-img-container par-strat'>
              <img src={require('../Partneri/images/fon.png')} className='par-img'alt="" />
              </div>

              <div className='par-img-child par-img-container par-nat' >
              <img src={require('../Partneri/images/hrfabrika.png')} className='par-img' alt="" />
              </div>

              <div className='par-img-child par-img-container par-med'>
              <img src={require('../Partneri/images/img.png')} className='par-img' alt="" />
              </div>
            </Slider>
          </div>:null} */}


          {parNatShow?<div className='par-child par-img-slider' id='par-nat-parent'>
            <Slider {...settings}>

              {imageNatList.map((image, index) => {
              return (<div className='par-img-child par-img-container' >
              <img src={image} className='par-img' alt="" />
              </div>)
})}
              
              
            </Slider>
          </div>:null}

          {parStratShow?<div className='par-child par-img-slider' id='par-strat-parent'>
            <Slider {...settings}>
              {
                parStratSlike.map((image, index) => {
                  return(
                    <div className='par-img-child par-img-container par-med'>
              <img src={image} className='par-img' alt="" />
              </div>
                  )
              })
            }
            </Slider>
          </div>:null}

          {parMedShow?
          <div className='par-child par-img-slider' id='par-med-parent'>
            <Slider {...settings}>
              <div className='par-img-child par-img-container par-strat' >
              <img src={medijski} className='par-img' alt="" />
              </div>
              
              <div className='par-img-child par-img-container par-strat' >
              <img src={medijski} className='par-img' alt="" />
              </div>

              <div className='par-img-child par-img-container par-strat'>
              <img src={medijski} className='par-img'alt="" />
              </div>

              <div className='par-img-child par-img-container par-nat' >
              <img src={medijski} className='par-img' alt="" />
              </div>
            </Slider>
          </div>:null}
        
        </div>
        
      </div>
    )
}

export default PartneriFullScreen;