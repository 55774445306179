export const blocks = [
    [
        { header: "Otvorene prijave 2. mart", text: "Za takmičenje se možete prijaviti popunjavanjem forme na sajtu." },
        { header: "Zatvorene prijave 16. mart", text: "Svi prijavljeni timovi će biti kontaktirani i obavešteni o prolasku u drugi krug selekcije." },
        { header: "IT test 19. mart", text: "Drugi krug selekcije – IT test koji se radi online, na Moodle platformi." },
        { header: "Tehnički intervju 22. mart", text: "Treći krug selekcije – online tehnički intervju sa predstavnicima Fakulteta organizacionih nauka." },
        { header: "FINALE 30. i 31. mart", text: "24-časovno takmičenje na Fakultetu organizacionih nauka, gde timovi osmišljavaju softversko rešenje na zadatu temu, nakon čega ga predstavljaju žiriju." },
    ],
    [
        { header: "Bootcamp 29. februar - 15. mart", text: "" },
        { header: "Otvorene prijave 2. mart", text: "" },
        { header: "Rad na projektima 15. mart", text: "" },
        { header: "Zatvorene prijave 16. mart", text: "" },
        { header: "Prezentacija rešenja 30. mart", text: "" },
        ]
];