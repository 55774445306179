import React, { useState, useEffect } from "react";
import Okvir from "./Okvir";
import strelica from "./Strelica.png";

const ZastoCard = () => {
  const zasto = [
    "Budite u korak sa tehnologijom! Učestvujte u razvoju rešenja aktuelnih problema u industriji.",
    "Pokažite KREATIVNOST i vaše veštine.",
    "Unapredite vaše iskustvo u PROGRAMIRANJU uz pomoć pravih stručnjaka.",
    "Posedujete timski duh i volite da se takmičite? Pošaljite vaše prijave i steknite nove kontakte i prijateljstva.",
    "Osvojite vredne nagrade.",
    "Uz pozitivnu atmosferu, međusobno motivisanje i druženje, kreiraju se aplikacije kakve do sad nisu viđene. Postanite i vi deo ovog nezaboravnog događaja. Čekamo vas!",
  ];
  const [tekstZasto, setTekstZasto] = useState(zasto[0]);
  const [icon, setIcon] = useState(null);
//   window.localStorage.setItem("textZ", tekstZasto);

  const handleTextChange = (forward) => {
    let index;
    if (forward) {
      index = zasto.indexOf(tekstZasto);
      if (index === zasto.length - 1) setTekstZasto(zasto[0]);
      else setTekstZasto(zasto[index + 1]);
    } else {
      index = zasto.indexOf(tekstZasto);
      if (index === 0) setTekstZasto(zasto[zasto.length - 1]);
      else setTekstZasto(zasto[index - 1]);
    }
  };

  useEffect(() => {
    setTekstZasto(zasto[0]);
  }, []);

  useEffect(() => {
    // window.localStorage.setItem("textZ", tekstZasto);
    if (zasto.indexOf(tekstZasto) === zasto.length - 1) setIcon(null);
    else setIcon(zasto.indexOf(tekstZasto) + 1);
  }, [tekstZasto]);

  return (
    <div className="card">
      <h1>Zašto se prijaviti?</h1>
      <div className="card-buttons">
        <img
          src={strelica}
          className="druga"
          onClick={() => handleTextChange(false)}
        />
        <Okvir tekst={tekstZasto} icon={icon} />
        <img src={strelica} onClick={() => handleTextChange(true)} />
      </div>
    </div>
  );
};

export default ZastoCard;
