import './tim.scss'

import nevena from './imgs/desktop/kordinatori/Nevena.JPG'
import dusan from './imgs/desktop/kordinatori/Dusan.JPG'
import joksa from './imgs/desktop/kordinatori/joksa.jpeg'
import pera from './imgs/desktop/kordinatori/pera.jpg'
import magdalena from './imgs/desktop/kordinatori/magdalena.jpg'
import nemanja from './imgs/desktop/kordinatori/Nemanja.jpg'
import vlada from './imgs/desktop/kordinatori/vlada.JPG'
import lace from './imgs/desktop/kordinatori/lace.jpg'

const TimMobilna = (props) => {

    // var slikeTimaMobilno = [require('./imgs/probne/mobilne/okvir.png'),require('./imgs/probne/mobilne/okvir1.png'),
    //                         require('./imgs/probne/mobilne/okvir.png'),require('./imgs/probne/mobilne/okvir1.png'),
    //                         require('./imgs/probne/mobilne/okvir.png'),require('./imgs/probne/mobilne/okvir1.png'),
    //                         require('./imgs/probne/mobilne/okvir.png')]

    
    var slikeMobilni = [dusan,
   nevena,
    joksa,
    pera,
    magdalena,
    nemanja,
    vlada,
    lace]     
                        
    var slikeTimaMobilno = [require('./imgs/desktop/timovi/Core.jpg'),require('./imgs/desktop/timovi/Core.jpg'),require('./imgs/desktop/timovi/IT.jpeg'),require('./imgs/desktop/timovi/Dizajn.jpg'),
                            require('./imgs/desktop/timovi/CR.jpg'),require('./imgs/desktop/timovi/PR.JPG'),require('./imgs/desktop/timovi/Logistika.JPG'),
                            require('./imgs/desktop/timovi/HR.jpg'),]


    return ( <div className="content">
        <p className='naslov'>Organizacioni tim</p>
        <div className = 'kord'>
            <button onClick={props.levo} className='strelica leva'><img src={require('./imgs/mobilna_verzija/leva_mobilno.png')}/></button>
            <img className='kordOkvir' src={require('./imgs/mobilna_verzija/kord_okvir_mobilno.png')} />
            <img className='slikaKord' src={slikeMobilni[props.brojac]}/>
            <button   onClick={props.desno} className='strelica desna'><img src={require('./imgs/mobilna_verzija/desna_mobilno.png')}/></button>
        </div>
        <p className='imeKord'>{props.ime}</p>
        <p className='kordZa'>{props.kordinator}</p>
        <img className='okvirSlike' src={require('./imgs/mobilna_verzija/Okvir za sliku.png')} />
        <img className='slikaTima' src={slikeTimaMobilno[props.brojac]}/>
        <div className='tim'>
            <p className='timZa'>{props.tim}</p>
            <div className='opisContainer'>
                <p className='opis'>{<b>{props.tim}</b>} {props.opis}</p>
             </div>
        </div>
        

        
        
    </div> );
}
 
export default TimMobilna;