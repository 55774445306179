import React from 'react'

import './Stars.scss'

export const Stars = (type) => {
  return (
    <>
    <div id='stars'></div>
<div id='stars2'></div>
<div id='stars3'></div>
    </>
  )
}
