import React, { useState } from 'react'
import './Header.scss'

const HeaderLogoOnly = ({ handleClick, openMenu, bezlogoa }) => {

    return (
        <header className={`${bezlogoa ? 'nemalogo' : ''}`}>
            <div className={'nav-logo-group'}>
                <div className='nav-fh'></div>
            </div>
            <div className={`nav-hamburger ${openMenu ? 'active' : ''}`} onClick={(handleClick)}>
                <span className="nav-hamburger-bar"></span>
                <span className="nav-hamburger-bar"></span>
                <span className="nav-hamburger-bar"></span>
            </div>
        </header>
    )
}

export default HeaderLogoOnly