import { Stars } from "../../components/Background/Stars";
import Header from "../../components/Header/Header";
import { useContext } from "react";
import { SwitchContextProvider } from "../../components/Switch/context/SwitchProvider";
import { HeaderContextProvider } from "../../components/Header/context/HeaderProvider";
import "./Faq.scss";
import FHGrupacija from "./FHGrupacija";
import BCGrupacija from "./BCGrupacija";
import HeaderSazvezdje from "../../components/Header/HeaderSazvezdje";

const Faq = () => {
    const [value] = useContext(SwitchContextProvider);
    const [usingBackground] = useContext(HeaderContextProvider);

    return (
        <>
            <HeaderSazvezdje imasv={true} />
            <div className={`Faq ${value && usingBackground ? 'Faq-bc' : ''}`}>
                <div className="flex-center-faq">
                    <div className="wrapper-faq">
                        <div className="questions-holder-faq">
                            <div className="title-center-faq">
                                <h1>Često postavljana pitanja</h1>
                            </div>
                            {value ? <BCGrupacija></BCGrupacija> : <FHGrupacija></FHGrupacija>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faq;