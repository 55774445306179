import React, { useState } from 'react';
import './FaqPitanjeBC.scss';


const FaqPitanjeBC = ({question, answer}) => {

    const [shown, setShown] = useState(false);
    const [firstLoaded, setFirstLoaded] = useState(true);

    function Prikazi () {
        if (!shown) {
            setShown(true);
            setFirstLoaded(false);
        }
        else setShown(false);
    }

    return (
        <div className='question-div-bc' style={{marginTop: "2rem"}}>
            <div className='title-div' onClick={Prikazi}>
                <div className='title-arrow-bc'>
                    <p>{question}</p>
                    <svg className='strelicaBC' viewBox="22 20 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_526_184)">
                    <path d="M44.0914 28.4192L38.1075 32.1234L32.1236 28.4192L32.1236 25L38.1075 28.4192L44.0914 25L44.0914 28.4192Z" fill="url(#paint0_linear_526_184)"/>
                    <path d="M46.086 35.2576L38.1075 40.1015L30.414 35.2576L30.414 30.9836L38.1075 35.5426L46.086 30.9836L46.086 35.2576Z" fill="url(#paint1_linear_526_184)"/>
                    <path d="M51.5 43.2358L38.1075 52.9236L25 43.2358L25 35.2576L38.1075 44.3755L51.5 35.2576L51.5 43.2358Z" fill="url(#paint2_linear_526_184)"/>
                    </g>
                    <path id='strela1BC' d="M44.0914 28.4192L38.1075 32.1234L32.1236 28.4192L32.1236 25L38.1075 28.4192L44.0914 25L44.0914 28.4192Z" fill="url(#paint3_linear_526_184)"/>
                    <path id='strela2BC' d="M46.086 35.2576L38.1075 40.1015L30.414 35.2576L30.414 30.9836L38.1075 35.5426L46.086 30.9836L46.086 35.2576Z" fill="url(#paint4_linear_526_184)"/>
                    <path id='strela3BC' d="M51.5 43.2358L38.1075 52.9236L25 43.2358L25 35.2576L38.1075 44.3755L51.5 35.2576L51.5 43.2358Z" fill="url(#paint5_linear_526_184)"/>
                    <defs>
                    <filter id="filter0_f_526_184" x="0" y="0" width="102.5" height="104.793" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_526_184"/>
                    </filter>
                    <linearGradient id="paint0_linear_526_184" x1="51.25" y1="79.7934" x2="51.25" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F3F2FF"/>
                    <stop offset="1" stop-color="#F3F2FF" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_526_184" x1="51.25" y1="79.7934" x2="51.25" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F3F2FF"/>
                    <stop offset="1" stop-color="#F3F2FF" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_526_184" x1="51.25" y1="79.7934" x2="51.25" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F3F2FF"/>
                    <stop offset="1" stop-color="#F3F2FF" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_526_184" x1="51.25" y1="80.32" x2="51.25" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F3F2FF"/>
                    <stop offset="1" stop-color="#F3F2FF" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_526_184" x1="51.25" y1="80.32" x2="51.25" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F3F2FF"/>
                    <stop offset="1" stop-color="#F3F2FF" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_526_184" x1="51.25" y1="80.32" x2="51.25" y2="25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F3F2FF"/>
                    <stop offset="1" stop-color="#F3F2FF" stop-opacity="0"/>
                    </linearGradient>
                    </defs>
                    </svg>
                </div>
                <div className='svg-div'>
                <svg id='obicanSvg' width="100%" height="34" viewBox="0 0 970 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    
                    <path id='line' d="M0 12 L369.154 12
                    L366.79 12 L426.417 31.5
                    M425.631 31.5 L539.213 31.5
                    L538.372 31.5 L597.163 13
                    M596.313 12 L965.457 12"
                    stroke="#D9D9D9" strokeWidth="5"/>
                    <path id='elipse' d="M947.623 11.5 A11.1886 11.5 0 1 0 970 11.5 A11.1886 11.5 0 1 0 947.623 11.5" fill="#D9D9D9"/>

                </svg>

                <svg id="gradientSvgBC" width="100%" height="34" viewBox="0 0 970 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="grad1BC" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor='#C539D9'>
                            </stop>
                            <stop offset="100%" stopColor='#7B2787'>
                            </stop>
                        </linearGradient>
                    </defs>
                    
                    <path id='gradientLineBC' className={shown ? 'active' : firstLoaded ? '' : 'default'} d="M0 12 L369.154 12
                    366.79 12 L426.417 31.5
                    425.631 31.5 L539.213 31.5
                    538.372 31.5 L597.163 13
                    596.313 12 L965.457 12"
                    stroke="url(#grad1BC)" strokeWidth="5"/>
                    <path id='gradientElipseBC' className={shown ? 'active' : ''} d="M947.623 11.5 A11.1886 11.5 0 1 0 970 11.5 A11.1886 11.5 0 1 0 947.623 11.5" fill="#7B2787"/>

                </svg>
                </div>
            </div>
            <div className={`answer-holder-bc ${shown ? "shown" : ""}`}>
                <div className='answer-div-bc'>
                    <p className='answer'>
                        {answer}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FaqPitanjeBC;