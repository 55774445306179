import './Partneri.scss'
import { Stars } from '../Background/Stars';
import parLevoDugme from "../Partneri/images/parLevoDugme.svg";
import parDesnoDugme from "../Partneri/images/parDesnoDugme.svg";
import {useState} from 'react'


const PartneriMobile = (props) => {

    return(
        <div className='par-mob-parent'>
            <Stars/>
          
            
            <div className='par-mob-child 'id='mob-pokrovitelj'>
              <p id='pokrovitelj-naslov'>Pokrovitelj <br />takmičenja</p>
              <div className='par-img-container' id='mob-pokrovitelj-kutija'>
                 <img src = {require('./images/Wargaming.png')} id='mob-pokrovitelj-slika' style={{maxWidth:"12rem"}}/> 
              </div>
            </div>

            <div className='par-mob-child' id='par-mob-kontejner'>
              <div className='par-mob-celina' id='par-mob-strat'>
                <p className='par-mob-naslov'>Strateški partneri</p>
                <div className='par-mob-grupa'>
                  <button onClick={props.parStratLevo} className='par-mob-btn'> 
                    <img src = {require('./images/levo_mobilno.png')} className='par-mob-btn-left'>
                    </img>
                  </button>
                  <div className='mpar-img-child par-img-container'>
                  <img src={props.parStratSlika} alt="nat" className='mpar-img' />
                  </div>
                  <button onClick={props.parStratDesno} className='par-mob-btn'>
                    <img src = {require('./images/desno_mobilno.png')} className='par-mob-btn-right'></img>
                  </button>
                </div>
              </div>

              <div className='par-mob-celina' id='par-mob-nat'>
                <p className='par-mob-naslov'>Naturalni partneri</p>
                <div className='par-mob-grupa'>
                  <button onClick ={props.parNatLevo} className='par-mob-btn'> 
                    <img src = {require('./images/levo_mobilno.png')} className='par-mob-btn-left'>
                    </img>
                  </button>
                  <div className='mpar-img-child par-img-container'>
                    <img src={props.parNatSlika} alt="nat" className='mpar-img' />
                  </div>
                  <button onClick={props.parNatDesno} className='par-mob-btn'>
                    <img src = {require('./images/desno_mobilno.png')} className='par-mob-btn-right'></img>
                  </button>
                </div>
              </div>

              {/* <div className='par-mob-celina' id='par-mob-med'>
                <p className='par-mob-naslov'>Medijski partneri</p>
                <div className='par-mob-grupa'>
                  <button onClick={props.parMedLevo} className='par-mob-btn'> 
                    <img src = {parLevoDugme} className='par-mob-btn-left'>
                    </img>
                  </button>
                  <div className='mpar-img-child par-img-container'>
                  <img src={props.parMedSlika} alt="nat" className='mpar-img' />
                  </div>
                  <button onClick={props.parMedDesno} className='par-mob-btn'>
                    <img src = {parDesnoDugme} className='par-mob-btn-right'></img>
                  </button>
                </div>
              </div> */}

          </div>
      </div>
     

    )
}

export default PartneriMobile;