const data = [
    {
        prvo_mesto: "120.000 RSD",
        drugo_mesto: "80.000 RSD",
        trece_mesto: "50.000 RSD",
    },
    {
        prvo_mesto: "1.000 EUR",
        drugo_mesto: "600 EUR",
        trece_mesto: "400 EUR",
    }
];

export default data;