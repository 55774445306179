export const quadrantDetection = (angle) => {
    angle = angle % 360;
    //console.log(angle);
    switch (Math.sign(angle) * Math.ceil(Math.abs(angle / 90))) {
        case 0: case 1: case -4:
            return 1;
        case 2: case -3:
            return 2;
        case -2: case 3:
            return 3;
        case -1: case 4:
            return 4;
        default:
            return 0;
    }
}

export function getCurrentRotation(el) {
    var st = window.getComputedStyle(el, null);
    var tm = st.getPropertyValue("-webkit-transform") ||
        st.getPropertyValue("-moz-transform") ||
        st.getPropertyValue("-ms-transform") ||
        st.getPropertyValue("-o-transform") ||
        st.getPropertyValue("transform") ||
        "none";
    if (tm !== "none") {
        var values = tm.split('(')[1].split(')')[0].split(',');
        /*
        a = values[0];
        b = values[1];
        angle = Math.round(Math.atan2(b,a) * (180/Math.PI));
        */
        return Math.round(Math.atan2(values[1], values[0]) * (180 / Math.PI)); //this would return negative values the OP doesn't wants so it got commented and the next lines of code added
        //var angle = Math.round(Math.atan2(values[1],values[0]) * (180/Math.PI));
        //return (angle < 0 ? angle + 360 : angle); //adding 360 degrees here when angle < 0 is equivalent to adding (2 * Math.PI) radians before
    }
    return 0;
}

export function generateRandomRotation(duration = undefined, currentAngle = 0) {
    let angle = (Math.random() > 0.5 ? -1 : 1) * Math.floor(Math.random() * (720 - 80 + 1)) + 80;

    if (duration) {
        return {
            angle: angle,
            duration: duration
        }
    }

    return {
        angle: currentAngle + angle,
        duration: Math.max(Math.sqrt(angle / 100), 1.5)
    }

}