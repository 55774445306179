import { useCallback, useState, useEffect } from 'react';

import React from 'react';
import HeaderSazvezdje from '../../components/Header/HeaderSazvezdje';


const Pravilnik = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = useCallback(() => {
    if (window.innerWidth < 1300) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }else{
      setWidth(window.innerWidth -500);
      setHeight(window.innerHeight);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1300) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }else{
      setWidth(window.innerWidth -500);
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div style={{display:'flex' }}>
    <HeaderSazvezdje imasv={false} bezlogoa={true} />
    <iframe src="https://drive.google.com/file/d/1A5MKeePwktzWUDMUfDWDWdndhVkf8CDc/preview" width={width} height={height} allow="autoplay" style={{position:"relative", left:0}}></iframe>
  
    </div>
    );
}

export default Pravilnik