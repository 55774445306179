import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './Agenda.scss';
import HeaderSazvezdje from '../../components/Header/HeaderSazvezdje';
import { SwitchContextProvider } from '../../components/Switch/context/SwitchProvider';
import Block from './components/Block'
import { blocks } from './data/blocks'
import { getBottom, getTop } from './util/getCords';
import { handleDotPosition, throttle } from './util/handleDotPosition';
import { generateRandomRotation, getCurrentRotation, quadrantDetection } from './util/quadrantDetection';


const Agenda = () => {
  const [value, toggleValue] = useContext(SwitchContextProvider);

  const contentRef = useRef(null);
  const blocksRef = useRef(null);

  const [distance, setDistance] = useState(0);
  const [focused, setFocused] = useState({ focused: 0 });
  const [popoutAstro, setPopoutAstro] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const astronaut = useRef(null);
  const [astronautMovement, setAstronautMovement] = useState(generateRandomRotation(2));
  const [registeredClick, setRegisteredClick] = useState(false);
  const [numberOfMountedChildren, setNumberOfMountedChildren] = useState(0);

  const handleBlockClick = useCallback((index) => () => {
    setFocused(prev => {
      if (prev.focused !== index) {
        setAstronautMovement(prev => generateRandomRotation(undefined, prev.angle));
        return {
          focused: index
        }
      }
      return prev;
    });

    setRegisteredClick(true);
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (blocksRef.current && contentRef.current) {
        const element = blocksRef.current;
        const content = contentRef.current;

        const documentFullHeight = document.body.scrollHeight;
        const contentOffsetTop = getTop(content);
        const contentOffsetBottom = getBottom(content);

        const availableMaxScrollDocument = documentFullHeight - window.innerHeight - contentOffsetTop - contentOffsetBottom;
        const maxScrollComponent = element.scrollHeight - element.offsetHeight;
        const blockScrollTop = (Math.max(0, window.scrollY - contentOffsetTop) / availableMaxScrollDocument) * maxScrollComponent;

        element.scrollTop = blockScrollTop;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    // Reinstancira objekat kako bi se okinuo useEffect hook koji sluša na focused elementu da bi centrirao astronauta opet
    const throttledResizeHandler = throttle(() => setFocused(prev => { return { ...prev } }), 200);
    window.addEventListener("resize", throttledResizeHandler);

    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true });
      window.removeEventListener("resize", throttledResizeHandler);
    }
  }, []);

  // eslint-ignore-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!registeredClick) {
      //console.log("mounted astrosnap");
      var handleAstronautSnappingOnScroll = throttle(() => {
        let len = blocks[value ? 1 : 0].length;
        let scrollBlock = Math.floor(window.scrollY / ((document.documentElement.scrollHeight - window.innerHeight) / len));
        if (scrollBlock === len) {
          scrollBlock = len - 1;
        }

        if (scrollBlock >= len) {
          return;
        }

        setFocused(prev => {
          if (prev.focused !== scrollBlock) {
            setAstronautMovement(prev => generateRandomRotation(undefined, prev.angle));
            return {
              focused: scrollBlock
            }
          }
          return prev;
        });
      }, 300);

      window.addEventListener('scroll', handleAstronautSnappingOnScroll);
    }
    return () => {
      //console.log("unmounted astrosnap");
      window.removeEventListener('scroll', handleAstronautSnappingOnScroll);
    }
  }, [registeredClick, value]);

  useEffect(() => {
    const izlazakAstronautaTimeout = setTimeout(() => {
      setPopoutAstro(true);
      handleDotPosition(setDistance, null, blocks[value ? 1 : 0].length - 1);
    }, 1000);
    return () => {
      clearTimeout(izlazakAstronautaTimeout);
    }
  }, [value]);

  useEffect(() => {
    if (numberOfMountedChildren === blocks[value ? 1 : 0].length) {
      //console.log("children mounted");
      handleDotPosition(setDistance, null, blocks[value ? 1 : 0].length - 1);
    }
  }, [numberOfMountedChildren, value]);

  /**
     * Menja poziciju tačke na promenu fokusa tačke.
     */
  useEffect(() => {
    handleDotPosition(setDistance, focused);
  }, [focused]);


  const handleAstroRotation = (event) => {
    const currentRotation = getCurrentRotation(document.querySelector('.agenda-astronaut'));
    const elem = astronaut.current;
    let rect = elem.getBoundingClientRect();
    let x = event.clientX - rect.left - rect.width / 2;
    let y = (-1) * (event.clientY - rect.top - rect.height / 2);

    const theta = Math.atan2(y, x) * 180 / Math.PI;
    let thetaRotated = theta + currentRotation;
    thetaRotated = (thetaRotated + 360) % 360;
    let q = quadrantDetection(thetaRotated);
    let rand = Math.floor(Math.random() * (1440 - 180 + 1)) + 180;
    let duration = Math.max(Math.sqrt(rand / 200), 0.5);


    switch (q) {
      case 1: case 3:
        setAstronautMovement(prev => {
          return {
            angle: prev.angle - rand,
            duration: duration
          }
        });
        break;
      case 2: case 4:
        setAstronautMovement(prev => {
          return {
            angle: prev.angle + rand,
            duration: duration
          }
        });
        break;
      default:
        return;
    }
  }

  const handleIzlazniPortal = () => {
    setDropDown(true);
    setPopoutAstro(false);

    setTimeout(() => {
      setDropDown(false);
      setAstronautMovement(generateRandomRotation(1));
      setRegisteredClick(false);
      setFocused({
        focused: 0
      })
      toggleValue();
      document.documentElement.scrollTo({ top: 0, behavior: 'instant' });
    }, 1000);
  }


  return (
    <>
      <HeaderSazvezdje imasv={true} bezlogoa={false} />
      <div className='agenda'>
        <div className='agenda-naslov'>
          <h1>Agenda</h1>
        </div>
        <div className='agenda-timeline-wrapper'
          ref={contentRef}
        >
          <div className='agenda-timeline' ref={blocksRef}>
            <div className={`agenda-ulazni-portal ${value ? 'aup-bc' : ''}`} />

            <div className='agenda-blocks'>
              {
                blocks[value ? 1 : 0].map((value, index) => {
                  return <Block data={value} onClick={handleBlockClick(index)} key={index} onMount={setNumberOfMountedChildren} />
                })
              }
              <div className='agenda-linija' />
              <div className={`agenda-astronaut-wrapper ${popoutAstro ? 'agenda-astronaut-popout' : ''}`}
                style={{ top: `${popoutAstro ? distance : (!dropDown ? -100 : distance + 400)}px` }}
              >
                <div className='agenda-astronaut' onClick={handleAstroRotation} ref={astronaut}
                  style={
                    {
                      transform: `rotate(${popoutAstro || dropDown ? astronautMovement.angle : 0}deg)`,
                      transitionDuration: `${popoutAstro || dropDown ? astronautMovement.duration : 0}s`
                    }
                  } />
              </div>
            </div>

            <div className={`agenda-izlazni-portal ${value ? 'aip-bc' : ''}`} onClick={handleIzlazniPortal} />

          </div>
        </div>
      </div>
    </>
  )
}

export default Agenda