import React from "react";
// import Footer from "../../components/Footer/Footer";
import OHakatonuCard from "./OHakatonuCard";
import "./Ohakatonu.scss";
import OBlockchainCard from "./OBlockchainCard";
import ZastoCard from "./ZastoCard";
import HeaderSazvezdje from "../../components/Header/HeaderSazvezdje";

const OHakatonu = () => {
  return (
    <>
      <HeaderSazvezdje imasv={false} />
      <div className="ohakatonu">
        {/* <Footer /> */}
        <OHakatonuCard />
        <OBlockchainCard />
        <ZastoCard />
      </div>
    </>
  );
};

export default OHakatonu;
