import { Stars } from "../Background/Stars";
import "./Iskustva.scss";
import HeaderSazvezdje from "../Header/HeaderSazvezdje";

import FaqPitanje from "../../pages/Faq/components/FaqPitanje/FaqPitanje";

const IskustvaFullScreen = (props) => {

  // console.log(props.slika);

  return (
    <div className="Iskustva">
      {/* <HeaderSazvezdje imasv={false} bezlogoa={true} /> */}
      {/* <div className='content'>
                <p className='naslov1'>Iskustva</p>
                <div className='iskustvo'>
                    <button onClick={props.levo} style={{"background-color": "transparent", "border": "none"}}><img className = 'leva' src={require('./imgs/leva_strelica.png')}  alt="" /></button>
                    <img className = 'okvir' src={require('./imgs/border_rect.png')}  alt="" />
                    <img className='slika' src={props.slika}></img>
                    <button onClick={props.desno} style={{"background-color": "transparent", "border": "none"}}><img className = 'desna' src={require('./imgs/desna strelica.png')}  alt="" /></button>
                </div>
                <p className='naslov2'>{props.ime}</p>
           </div> */}

      <p className="naslov1">ISKUSTVA</p>
      <div className="content">
        <button
          onClick={props.levo}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <img
            className="leva"
            src={require("./imgs/leva_strelica.png")}
            alt=""
          />
        </button>
        {/* <img className='okvir' src={require('./imgs/border_rect.png')} alt="" /> */}

        <div className="kutija">
          
          { typeof props.slika  != "object" ?( <>
          <img
            className="okvir"
            src={require("./imgs/border_rect.png")}
            alt=""
          />
          <video className="slika" autoPlay  src={props.slika}> </video>
          </>
          ) : <>
            {props.slika.pitanja_i_odgovori.map((pitanje, index) => {
              return (
                <FaqPitanje
                  key={index}
                  question={pitanje.pitanje}
                  answer={pitanje.odgovor}
                />
              );
            })}
          </>} 
          </div>
          {/* <iframe src="https://drive.google.com/file/d/1YLrM8uZtcw9IAJH2Qvv6KGL5sl0iNdpJ/preview" className="slika" allow="autoplay"></iframe> */}
        
        <button
          onClick={props.desno}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <img
            className="desna"
            src={require("./imgs/desna strelica.png")}
            alt=""
          />
        </button>
      </div>
        <p className="naslov2">{props.ime}</p>
        <p className="naslov3">{props.tim}</p>
      {/* <GestureDetector onSwipeLeft={props.levo} onSwipeRight={props.desno}><img className='slika' src={props.slika}></img></GestureDetector>  */}
    </div>
  );
};

export default IskustvaFullScreen;
