export const getCoords = (elem) => {// crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.scrollX || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: top, left: left };
}
export const getTop = (elem) => {
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;

    var clientTop = docEl.clientTop || body.clientTop || 0;

    var top = box.top + scrollTop - clientTop;

    return top;
}

export const getBottom = (elem) => {
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    
    var bottom = docEl.scrollHeight - (box.top + scrollTop - clientTop + elem.scrollHeight);
   
    return bottom;
}