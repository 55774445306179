
import nevena from '../imgs/desktop/kordinatori/Nevena.JPG'
import dusan from '../imgs/desktop/kordinatori/Dusan.JPG'
import joksa from '../imgs/desktop/kordinatori/joksa.jpeg'
import pera from '../imgs/desktop/kordinatori/pera.jpg'
import magdalena from '../imgs/desktop/kordinatori/magdalena.jpg'
import nemanja from '../imgs/desktop/kordinatori/Nemanja.jpg'
import vlada from '../imgs/desktop/kordinatori/vlada.JPG'
import lace from '../imgs/desktop/kordinatori/lace.jpg'


export const slikeDesktop = [dusan,
    nevena,
     joksa,
     pera,
     magdalena,
     nemanja,
     vlada,
     lace] ;

export const slikeTimeDesktop = [require('../imgs/desktop/timovi/Core.jpg'), require('../imgs/desktop/timovi/Core.jpg'), require('../imgs/desktop/timovi/IT.jpeg'), require('../imgs/desktop/timovi/Dizajn.jpg'),
require('../imgs/desktop/timovi/CR.jpg'), require('../imgs/desktop/timovi/PR.JPG'), require('../imgs/desktop/timovi/Logistika.JPG'),
require('../imgs/desktop/timovi/HR.jpg'),]