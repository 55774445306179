import React from 'react';
import ZapravoNagrada from './ZapravoNagrada';
import data from './data/data';
import './Nagrade.scss';
import HeaderSazvezdje from '../../components/Header/HeaderSazvezdje';

const Nagrade = () => {
    return (
        <>
            <HeaderSazvezdje imasv={true} />
            <div className='nagrade'>
                <div className='nagrade-naslov'>
                    <h1>Nagrade</h1>
                </div>
                <div className='nagrade-podijum-wrapper'>
                    <div className='nagrade-podijum'>
                        <ZapravoNagrada data={data} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Nagrade