import { GestureDetector } from "onsenui";
import "./Iskustva.scss";
import { Stars } from "../Background/Stars";
import swipe from "swipe-js-iso";
import HeaderSazvezdje from "../Header/HeaderSazvezdje";

import FaqPitanje from "../../pages/Faq/components/FaqPitanje/FaqPitanje";

const IskustvaMobile = (props) => {
  const swipies = {};

  return (
    <div className="Iskustva">
      {/* <HeaderSazvezdje imasv={false} /> */}
      {/* <div>
                <img className='logo' src={require('./imgs/FH Logo.png')} alt='logo' />
                <img className='meni' src={require('./imgs/Meni.png')} alt='meni' />
            </div> */}
      <p className="naslov1">ISKUSTVA</p>
      <div className="content">
        <button
          onClick={props.levo}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <img
            className="leva"
            src={require("./imgs/levo_mobilno.png")}
            alt=""
            style={{  width:`${typeof props.slika  == "object" ? "50px": "auto" }` }}
          />
        </button>
        {/* <img className='okvir' src={require('./imgs/okvir_mobilno.png')} alt="" /> */}
        <div className="kutija">
        { typeof props.slika  != "object" ?( <>
          <img
            className="okvir"
            src={require("./imgs/okvir_mobilno.png")}
            alt=""
          />
          <video className="slika" autoPlay  src={props.slika}> </video>
          </>
          ) : <>
            {props.slika.pitanja_i_odgovori.map((pitanje, index) => {
              return (
                <FaqPitanje
                  key={index}
                  question={pitanje.pitanje}
                  answer={pitanje.odgovor}
                />
              );
            })}
          </>} 
        </div>
        <button
          onClick={props.desno}
          style={{ backgroundColor: "transparent", border: "none"} }
        >
          <img
            className="desna"
            src={require("./imgs/desno_mobilno.png")}
            alt=""
            style={{  width:`${typeof props.slika  == "object" ? "50px": "auto" }` }}
          />
        </button>
      </div>
        <p className="naslov2">{props.ime}</p>
        <p className="naslov3">{props.tim}</p>
      {/* <GestureDetector onSwipeLeft={props.levo} onSwipeRight={props.desno}><img className='slika' src={props.slika}></img></GestureDetector>  */}
      {/* <img className='slika' src={props.slika} alt=""></img> */}
    </div>
  );
};

export default IskustvaMobile;
