import React, { useContext, useEffect, useState } from 'react'
import './Header.scss';
import SwitchContext from '../Switch/components/SwitchContext'
import { SwitchContextProvider } from '../Switch/context/SwitchProvider';
import { HeaderContextProvider } from './context/HeaderProvider';

const Header = ({ handleClick, openMenu, bezlogoa }) => {
  const [value] = useContext(SwitchContextProvider);
  const setUsingBackground = useContext(HeaderContextProvider)[1];

  useEffect(() => {
    if (setUsingBackground !== undefined) {
      setUsingBackground(true);
      return () => {
        setUsingBackground(false);
      }
    }
  }, [setUsingBackground]);



  return (
    <header className={`${bezlogoa ? 'nemalogo' : ''}`}>
      <div className='nav-logo-group'>
        <div className='nav-fh'></div>
        <SwitchContext />
        <div className='nav-bc'></div>
      </div>
      <div className={`nav-hamburger ${openMenu ? 'active' : ''} ${value ? 'nav-hamburger-bc' : ''}`} onClick={handleClick}>
        <span className="nav-hamburger-bar"></span>
        <span className="nav-hamburger-bar"></span>
        <span className="nav-hamburger-bar"></span>
      </div>
    </header>
  )
}

export default Header