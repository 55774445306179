import './Footer.scss'
import ftFb from "../Footer/images/ftFb.svg"
import ftFonislogo from "../Footer/images/ftFonisLogo.svg"
import ftGmail from "../Footer/images/ftGmail.svg"
import ftIg from "../Footer/images/ftIg.svg"
import ftLinkedin from "../Footer/images/ftLinkedin.svg"
import ftMap from "../Footer/images/ftMap.svg"
import ftTt from "../Footer/images/ftTt.svg"
import { Stars } from '../Background/Stars';

function Footer() {
  return (

    <footer>
      <div id='ft-parent'>
        <ul className='ft-child' id='ft-adr-parent'>
          <li className='ft-adr-child'>
            <img id='ft-mapa' src={ftMap} alt='mapa'></img>
          </li>
          <li className='ft-adr-child'>
            <p id='ft-adr-tekst'>
              Fakultet organizacionih nauka<br></br>Jove Ilića 154, Beograd
            </p>
          </li>
        </ul>

        <img className='ft-child' id='ft-logo' src={ftFonislogo} alt='logo'>
        </img>

        <ul className='ft-child' id='ft-sajt'>
          <li className='ft-cont-child'>
            <a href='https://fonis.rs/' className='ft-cont-link'>www.fonis.rs</a>
          </li>
          <li className='ft-child-child'>
            <a href='https://elab.fon.bg.ac.rs/' className='ft-cont-link'>www.elab.fon.bg.ac.rs</a>
          </li>
          <li className='ft-child-child'>
            <a href='https://bc.elab.fon.bg.ac.rs/' className='ft-cont-link'>bc.elab.fon.bg.ac.rs</a>
          </li>
        </ul>
      </div>
      <div id='ft-soc-joksa'>
      <ul id='ft-soc'>
        <li className='ft-soc-item'>
          <a href='https://www.tiktok.com/@fonis_fon' className='ft-soc-link'>
            <button className='ft-soc-btn'>
              <img src={ftTt} className='ft-soc-img' id='ft-soc-tt' alt='tiktok'></img>
            </button>
          </a>
        </li>

        <li className='ft-soc-item'>
          <a href='hakaton@fonis.rs' className='ft-soc-link'>
            <button className='ft-soc-btn'>
              <img src={ftGmail} className='ft-soc-img' id='ft-soc-gm' alt='gmail'></img>
            </button>
          </a>
        </li>

        <li className='ft-soc-item'>
          <a href='https://www.linkedin.com/company/fonis' className='ft-soc-link'>
            <button className='ft-soc-btn'>
              <img src={ftLinkedin} className='ft-soc-img' id='ft-soc-ln' alt='linkedin'></img>
            </button>
          </a>
        </li>

        <li className='ft-soc-item'>
          <a href='https://www.facebook.com/fonis.rs/?locale=sr_RS' className='ft-soc-link'>
            <button className='ft-soc-btn'>
              <img src={ftFb} className='ft-soc-img' id='ft-soc-fb' alt='facebook'></img>
            </button>
          </a>
        </li>

        <li className='ft-soc-item'>
          <a href='https://www.instagram.com/fonis_fon/' className='ft-soc-link'>
            <button className='ft-soc-btn'>
              <img src={ftIg} className='ft-soc-img' id='ft-soc-ig' alt='instagram'></img>
            </button>
          </a>
        </li>
      </ul>
      </div>
    </footer>

  )
}


export default Footer;

