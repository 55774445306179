import React, { useEffect, useRef, useState } from 'react'
import './Switch.scss'

/**
 * Iz nekog razloga switch voli ponekad da missfire-uje ali nikako da saznam kako...
 * Takođe ne znam kako rešim problem ukidanja "tranzicije" kada se resize-uje window nad 
 * pseudo elementom ::before. U trenutnom stanju kad se resizeuje on smoothly pomeri dugmence.
 * Možda da se doda nešto nekako negde u kodu da "tranzicija" postoji samo za vreme kliktanja
 * 
 * @param {Function} onClick callback na klik
 * @param {Number} animationTimeout vreme pre početka animacije
 * @param {Number} animationDuration dužina animacije u milisekundama, početna vrednost je 2s
 * @param {Boolean} animationOnScrollBottomHit pokreće animaciju kad se dodirne dno stranice i clear-uje timeout animaciju 
 * @param {Boolean} isCheckedExternal extreno podešavanje vrednosti -> `localValue` je onemogućen. Default vrednost je "undefined" i Switch radi na osnovu lokalne vrednosti.
 * @param {Boolean} defaultValue podešavanje početne lokalne vrednosti -> radi samo ukoliko `isCheckedExternal === undefined` u suprotnom ne.
 */
const Switch = ({
    onClick = undefined,
    animationTimeout = 5000,
    animationDuration = undefined,
    animationOnScrollBottomHit = true,
    isCheckedExternal = undefined,
    defaultValue = undefined
}) => {

    const [timeoutID, setTimeoutID] = useState(null);
    const [anim, setAnim] = useState(false);
    const [localValue, setLocalValue] = useState(defaultValue ?? false);
    const [animationState, setAnimationState] = useState(isCheckedExternal ? isCheckedExternal : localValue);
    const animRef = useRef(null);

    // Početni timeout pred blistanje
    useEffect(() => {
        const timeout = setTimeout(() => {
            animate(true);
        }, animationTimeout);
        setTimeoutID(timeout);
        return () => {
            if (timeout) clearTimeout(timeout);
        }

    }, [animationTimeout]);

    // Uklanja animaciju
    useEffect(() => {
        const element = animRef.current;
        if (element) {
            const removeClass = (event) => {
                // console.log("gotova animacija")
                setAnim(false);
            }
            element.addEventListener('animationend', removeClass);
            return () => element.removeEventListener("animationend", removeClass);
        }
    }, [animRef]);

    // Podešavanje za animaciju pri dodiru dna strane
    useEffect(() => {
        if (animationOnScrollBottomHit) {
            const handleScroll = () => {
                if ((window.innerHeight + Math.ceil(window.scrollY)) >= document.body.offsetHeight) {
                    if (timeoutID) {
                        clearTimeout(timeoutID);
                    }

                    animate(true);
                }
            }

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [animationOnScrollBottomHit, timeoutID]);

    //Promena tipa animation-timeline-function tako da ostane perzistentna za vreme kliktanja dugmeta
    //Moguće je da nastane bag ako se klikne tri miliona puta i setValue() bude različita vrednost od trenutne localValue
    useEffect(() => {
        if (!anim) {
            setAnimationState(isCheckedExternal ? isCheckedExternal : localValue);
        }
    }, [isCheckedExternal, localValue, anim]);

    const animate = (value) => {
        setAnim(value);
    }

    const handleChange = () => {
        if (onClick !== undefined || isCheckedExternal !== undefined) {
            onClick();
        }
        if (defaultValue !== undefined || isCheckedExternal === undefined) {
            setLocalValue(prev => !prev);
        }
        if (timeoutID) {
            clearTimeout(timeoutID);
        }
    }

    return (
        <div className={`switch-container `}>
            <input type='checkbox' id="check" onChange={() => handleChange()} checked={isCheckedExternal ? isCheckedExternal : localValue} />
            <label
                htmlFor='check'
                className={`switch-dugme ${anim ? (animationState ? "switch-pulse-bc" : "switch-pulse-fh") : ""}`}
                ref={animRef}
                style={{
                    animationDuration: animationDuration ? `${animationDuration / 1000}s` : undefined,
                }}
            />
        </div>
    )
}

export default Switch