import FaqPitanjeBC from "./components/FaqPitanjeBC/FaqPitanjeBC";

function BCGrupacija () {
    return (
        <>
            <FaqPitanjeBC question={"Kad se održava FON Hakaton – Blockchain Challenge?"} answer={"Timovi će krenuti sa radom na svojim projektima početkom marta, dok će prezentacija rešenja na FON Hakaton – Blockchain Challenge-u održati 30.marta."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Gde se održava FON Hakaton – Blockchain Challenge?"} answer={"Ovogodišnji FON Hakaton – Blockchain Challenge se održava u prostorijama Fakulteta organizacionih nauka i online."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Ko može učestvovati?"} answer={"Na ovogodišnjem FON Hakatonu - Blockchain Challenge mogu učestvovati studenti svih nivoa studija (osnovne, master, specijalističke i doktorske studije) kao i učenici srednjih škola."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Kada počinju prijave za FON Hakaton – Blockchain Challenge?"} answer={"Prijave za ovogodišnji FON Hakaton – Blockchain Challenge počinju od 2. marta."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Kada se zatvaraju prijave za FON Hakaton – Blockchain Challenge?"} answer={"Prijave za ovogodišnji FON Hakaton – Blockchain Challenge se zatvaraju 16. marta."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Da li je neophodno prethodno pohađanje Blockcahin Bootcamp-a?"} answer={"Ne, učesnici se mogu prijaviti na FON Hakaton i prezentovati svoje rešenje bez prethodnog pohađanja Blockchain Bootcamp-a."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Da li je učešće na FON Hakaton – Blockchain Challenge-u besplatno?"} answer={"Da, učešće je potpuno besplatno za sve kandidate."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Da li je moguće online prezentovanje projekta?"} answer={"Da, učesnici mogu svoje projekte prezentovati i online ukoliko nisu u mogućnosti da prisustvuju takmičenju uživo."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"U kojim tehnologijama se može raditi projekat?"} answer={"Studenti mogu da kreiraju svoje projekte u svim inovativnim Web3 tehnologijama kao što su PyTeal i Solidity."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Da li postoji mentorski program tokom izrade projekta?"} answer={"Da, rad sa mentorima počinje 15. marta i mentori će biti na raspolaganju svim kandidatima na Fakultetu organizacionih nauka i/ili online."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Do kada je potrebno predati projekat?"} answer={"Projekat je neophodno predati do 26. marta."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Gde treba predati projekat?"} answer={"Projekat je neophodno okačiti u okviru predviđenog zadatka na Moodle platformi. Svi učesnici će prethodno biti upisani na odgovarajući kurs."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Da li postoje neke nagrade za najbolje timove?"} answer={"Da, obezbeđene su novčane nagrade za najbolja tri tima, dok ostali timovi imaju mogućnost da osvoje specijalne nagrade u određenim kategorijama."}></FaqPitanjeBC>
            <FaqPitanjeBC question={"Kako izgleda proces izbora pobednika?"} answer={"Nakon što svi timovi prezentuju svoja rešenja, stručni žiri na osnovu nekoliko kriterijuma donosi odluku o tome kome će dodeliti nagrade."}></FaqPitanjeBC>

        
        </>
    );
}

export default BCGrupacija;