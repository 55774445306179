import React, { createContext } from 'react'
import useStorageState from './useStorageState';

export const SwitchContextProvider = createContext();

/**
 * Pruža vrednost promenljive iz lokalnog storage-a i beleži je 
 */
const SwitchProvider = ({ children }) => {
    const [value, toggleValue] = useStorageState('bc-state', false);

    return (
        <SwitchContextProvider.Provider value={[value, toggleValue]}>
            {children}
        </SwitchContextProvider.Provider>
    )
}

export default SwitchProvider