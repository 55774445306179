export const imena = ['Dušan Kostić', 'Nevena Cvijović', 'Aleksandar Joksimović', 'Petar Pavlović', 'Magdalena Bojanić', 'Nemanja Planić', 'Vladimir Drobnjak', 'Lazar Jovanović'];
export const kordinatori = ['Koordinatori takmičenja', 'Koordinatori takmičenja', 'Koordinator tima za informacione tehnologije', 'Koordinator tima za dizajn', 'Koordinatorka tima odnose sa kompanijama '
    , 'Koordinator tima za odnose s javnošću', 'Koordinator tima za logistiku', 'Koordinator tima za ljudske resurse'];
export const timovi = ['Tim koordinatora', 'Tim koordinatora', 'Tim za informacione tehnologije', 'Tim za dizajn', 'Tim za odnose sa kompanijama',
    'Tim za odnose s javnošću', 'Tim za logistiku', 'Tim za ljudske resurse'];

export const opisi = ['u saradnji sa članovima svojih timova radi na tome da 11. FON Hakaton premaši sva moguća očekivanja.',
    'u saradnji sa članovima svojih timova radi na tome da 11. FON Hakaton premaši sva moguća očekivanja.',
    'bio je prevashodno zadužen za izradu veb-sajta takmičenja.',
    'na kreativan način osmišlja vizuelni identitet takmičenja, kreira objave na društvenim mrežama, kao i celokupni promo materijal.',
    'ostvaruje saradnju i sklapa partnerstva sa mnogobrojnim kompanijama, što izuzetno doprinosi realizaciji takmičenja.',
    'bavio se marketinškom kampanjom takmičenja, sa ciljem da što više mladih programera sazna za FON Hakaton, ali i dobije želju da se oproba u tom izazovu.',
    'će se postarati da na takmičenju sve protekne u skladu sa operativnim planom koji je prethodno napravljen i potrudiće se da Hakaton takmičarima pruži divno iskustvo.',
    'zadužen je za selekciju prijavljenih timova i održavanje pozitivne atmosfere unutar organizacionog tima.'];
