import React, { createContext, useState } from 'react'

export const HeaderContextProvider = createContext();

const HeaderProvider = ({ children }) => {
  const [usingBackground, setUsingBackground] = useState(false);

  return (
    <HeaderContextProvider.Provider value={[usingBackground, setUsingBackground]}  >
      {children}
    </HeaderContextProvider.Provider>
  )
}

export default HeaderProvider