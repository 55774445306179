import { useState, useTransition } from "react";
import TimDesktop from "./tim_desktop";
import Media from "react-media";
import matchers from "@testing-library/jest-dom/matchers";
import React, { Fragment } from 'react';
import TimMobilna from "./tim_mobilna";
import HeaderSazvezdje from "../Header/HeaderSazvezdje";
import { imena, kordinatori, opisi, timovi } from "./data/data";

const Tim = () => {
    var [brojac, setBrojac] = useState(0);
    var [ime, setIme] = useState(imena[0]);
    var [kordinator, setKordinator] = useState(kordinatori[0]);
    var [tim, setTim] = useState(timovi[0]);
    var [opis, setOpis] = useState(opisi[0]);
    
    
    const levo = ()=>{
        if(brojac != 0){
            setBrojac(--brojac);
            setIme(imena[brojac]);
            setKordinator(kordinatori[brojac]);
            setTim(timovi[brojac])
            setOpis(opisi[brojac]);
            
        }else {
            setIme(imena[imena.length-1]);
            setKordinator(kordinatori[kordinatori.length-1]);
            setTim(timovi[timovi.length-1])
            setBrojac(imena.length-1);
            setOpis(opisi[opisi.length - 1]);
            
            
        }
    }

    const desno = ()=>{
        if(brojac != imena.length-1){
            setBrojac(++brojac);
            setIme(imena[brojac]);
            setTim(timovi[brojac])
            setKordinator(kordinatori[brojac]);
            setOpis(opisi[brojac]);
            

        } else {
            setIme(imena[0]);
            setKordinator(kordinatori[0]); 
            setTim(timovi[0]);
            setOpis(opisi[0]);
            
            setBrojac(0);

        }
    }

    return (
        <div className="timKomponenta">
            <HeaderSazvezdje  bezlogoa={false}/>
            <Media queries={
                {
                    mobilna: "(max-width: 849px)",
                    desktop: "(min-width: 850px)"
                }}>
                    {
                        matches => (
                            <Fragment>
                                {matches.mobilna && <TimMobilna levo = {() => {levo()}} desno = {()=> {desno()}} brojac={brojac} ime={ime} kordinator={kordinator} tim={tim} opis={opis} />}
                                {matches.desktop && <TimDesktop levo = {() => {levo()}} desno = {()=> {desno()}} brojac={brojac} ime={ime} kordinator={kordinator} tim={tim} opis={opis} />}
                            </Fragment>
                        )    
                    }
            </Media>
            {/* <TimDesktop levo = {() => {levo(slikeDesktop, slikeTimeDesktop)}} desno = {()=> {desno(slikeDesktop, slikeTimeDesktop)}} ime={ime} kordinator={kordinator} tim={tim} opis={opis} slika={slika} slikaTima = {slikaTima}/> */}
        </div>
    );
}

export default Tim;