import React, {useState} from 'react';
import './Zvezda.scss';
import { useNavigate } from 'react-router-dom';

import {animate, motion} from 'framer-motion';

const Zvezda = ({ zvezda }) => {


    const [isScaling, setIsScaling] = React.useState(false);

    const navigate = useNavigate();
    let relativeX = 0;
    let relativeY = 0;

    const handleStarClick = (link) => {
        setTimeout(() => {
            navigate(link);
            
        }, 2000);
    };

    return (
        <div className='star'
            id={`zvezda${zvezda.id}`}
            style={{
                left: zvezda.kord.x,
                top: zvezda.kord.y,
            }}>
            {/* <div className='krug'></div> */}
            <motion.img src={require('./Zvezda.png')}
                initial={{ scale: 1 }}
                animate={{ scale: isScaling? [1, 200, 1] : 1 }}
                whileTap={{ scale: 10 }}
                // whileTap={{  scale: 400 }}
                transition={{ duration: 2.5 }}
            onClick={() =>{
                setIsScaling(true);
                handleStarClick(zvezda.link)
            }
                 } 
                 />
            <div className='ime'><div onClick={() => {
                setIsScaling(true);
                handleStarClick(zvezda.link)
            }}>{zvezda.ime}</div></div>
        </div>
    );
};


export default Zvezda;