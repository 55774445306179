import React from 'react'
import Switch from './Switch';
import { SwitchContextProvider } from '../context/SwitchProvider';
import { useContext } from 'react';

/**
 * Da slušate promene nad dugmetom napravite `useEffect` tako da ima dependancy `value` uzeto it `SwitchProvider`.
 * ***Napomena***: pazite gde stavljate Switch komponentu, nije imeplementiran nikakav "Delay on unmount". Animacija će se prekinuti odmah. Može se izbeći ako grupišete sve komponente koje implementiraju ovo dugme sa jednom rutom i propustiti ih sa `<Outlet/>` komponentom.
 * @param {Function} callback poziva se kad se promeni stanje promenljive
 * @example
 * 
 * 1. **Primer korišćenja vrednosti context-a**
 *  ```
 *  import { SwitchContextProvider } from '../context/SwitchProvider';
 *  const value = useContext(SwitchContextProvider)[0];
 *  useEffect(() => {
 *      //Logika za izvršavanje kada se promeni vrednost dugmeta
 *  }, [value]);
 *  ```
 * 2. **Primer grupisanja preko ruta**
 * ```
 * return (
 *      <BrowserRouter>
 *          <Router>
 *              <Route element={<DugmeWrapper/>}>
 *                  //... vaše rute koje implementiraju dugme
 *              </Route>
 *          </Router>
 *      </BrowserRouter>
 * );
 * ```
 * 3. **Primer DugmeWrapper**
 * ```
 * const DugmeWrapper = () => {
 *      return (
 *          <>
 *              <HeaderSlika/>
 *              <SwitchContext/>
 *              <HeaderSlika/>
 *              <Meni/>             
 *              <Outlet/>
 *          </>
 *      )
 * }
 * ```
 */
const SwitchContext = ({
    onClick = undefined,
    ...props
}) => {
    const [value, toggleValue] = useContext(SwitchContextProvider);

    return (
        <Switch
            isCheckedExternal={value}
            onClick={() => {
                toggleValue();
                if (onClick) onClick();
            }}
            {...props}
        />
    )
}

export default SwitchContext