import "@fontsource/noto-sans";
import "./App.scss";
import { Stars } from './components/Background/Stars';
import { Routes, Route, useLocation, redirect } from 'react-router-dom';

import Partneri from "./components/Partneri/Partneri";
import Pocetna from "./components/Pocetna/Pocetna";
import Nagrade from "./pages/Nagrade/Nagrade";
import Iskustva from "./components/Iskustva/Iskustva";
import Faq from "./pages/Faq/Faq";

import React, { useContext, useEffect } from "react";
import { SwitchContextProvider } from "./components/Switch/context/SwitchProvider";
import { HeaderContextProvider } from "./components/Header/context/HeaderProvider";

import Loading from "./pages/Loading/Loading";
import Tim from './components/tim/tim';
import Sazvezdje from "./components/NavigacioniMeni/Sazvezdje/Sazvezdje";

import OHakatonu from "./pages/OHakatonu/OHakatonu";
import Footer from './components/Footer/Footer'
import Agenda from "./pages/Agenda/Agenda";

// import Prijava2 from "./pages/Prijava2/Prijava2";
import Pravilnik from "./pages/Pravilnik/Pravilnik";


function App() {

  const [value] = useContext(SwitchContextProvider);
  const [usingBackground] = useContext(HeaderContextProvider);
  const [isLoading, setIsLoading] = React.useState(true);

  let location = useLocation();

  useEffect(() => {
    document.title = "FON Hakaton";
    setTimeout(() => {
      setIsLoading(false);

    }, 3000);
  }, [])


  if (isLoading) {
    return <Loading />
  }


  return (
    <div
      className={`App ${value && usingBackground ? 'App-bc' : ''}`}
      style={{ minHeight: "100vh", Width: "100vw", position: "relative" }}
    >
      <div className="App-content">
      {/* { isLoading ? <Loading /> : null} */}
      {/* <Pravilnik /> */}
      {/* <Pravilnik /> */}
      <Stars />
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Pocetna />} />
          <Route path="/nagrade" element={<Nagrade />} />
          <Route path="/iskustva" element={<Iskustva />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/tim" element={<Tim />} />
          <Route path="/partneri" element={<Partneri />} />
          <Route path="/pravilnik" element={<Pravilnik />} />
          {/* <Route path="/prijava" element={<Prijava2 />} /> */}
          <Route path="/o-hakatonu" element={<OHakatonu />} />
          <Route path="/*" element={
            <>
              <h2 style={{color: "#24BDDE", fontFamily:"Orbitron", position:"absolute", top:"25%", left:"50%", transform:"translate(-50%,-75%)"}}>404 not found</h2>
            </>
          } />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
