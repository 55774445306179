import { slikeDesktop, slikeTimeDesktop } from './data/imageDataDesktop';
import './tim.scss'
const TimDesktop = (props) => {
    

    return ( <div className="Content">
        <p className='naslov'>Organizacioni tim</p>
        <div className="kordinator">
           <button onClick={props.levo} className='strelica'><img  src={require('./imgs/desktop/leva_strelica.png')} alt='leva'/> </button> 
           <img className='korOkvirSlika' src={require('./imgs/desktop/kor_okvir.png')} alt='okvir'/>
           <img className='slikaKord' src={slikeDesktop[props.brojac]} alt='korSlika'/>
           <button style={{'position':'relative', 'right':'155px'}} onClick={props.desno} className='strelica'><img  src={require('./imgs/desktop/desna_strelica.png')} alt='desna'/> </button> 
        </div>
        <div className='kordtekst'>
            <p className='podnaslov1'>{props.ime}</p>
            <p className = 'opis' >{props.kordinator}</p>
        </div>
        <div className="timovi">
            <img className='okvirTim' src={require('./imgs/desktop/Okvir za sliku tima.png')} alt='okvir tima'/>
            <img className='timSlika' src={slikeTimeDesktop[props.brojac]} alt='slika tima'/>
            <div className='tekst'>
                <p  className='podnaslov1'> {props.tim}</p>
                <p style={{fontSize: '16px', 'fontFamily' : 'Noto Sans'}}>{<b>{props.tim}</b>} {props.opis}</p>
            </div>
        </div>
    </div> );
}
 
export default TimDesktop;