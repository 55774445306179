import React, {useState} from 'react';
import Zvezda from '../Zvezda/Zvezda';
import './Sazvezdje.scss';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';


const Sazvezdje = ({ className, funkcija }) => {


    const zvezdeCords = [
        { id: 1, kord: { x: 45, y: 0 } },
        { id: 2, kord: { x: 175, y: 10 } },
        { id: 3, kord: { x: 135, y: 120 } },
        { id: 4, kord: { x: 225, y: 165 } },
        { id: 5, kord: { x: 35, y: 180 } },
        { id: 6, kord: { x: 120, y: 300 } },
        { id: 7, kord: { x: 0, y: 375 } },
        { id: 8, kord: { x: 195, y: 390 } },
    ];

    const stranice = [
        { ime: 'Početna', link: '/' },
        { ime: 'O takmičenju', link: '/o-hakatonu' },
        { ime: 'Nagrade', link: '/nagrade' },
        { ime: 'Agenda', link: '/agenda' },
        { ime: 'Iskustva', link: '/iskustva' },
        { ime: 'FAQ', link: '/faq' },
        { ime: 'Tim', link: '/tim' },
        { ime: 'Partneri', link: '/partneri' },
        { ime: 'Pravilnik', link: '/pravilnik' },
    ]

    const trenutnaStrana = useLocation().pathname;
    const filterovaneStrane = stranice.filter((strana) => trenutnaStrana !== strana.link);
    const zvezde = filterovaneStrane.map((value, index) => {
        return {
            id: index,
            ime: value.ime,
            kord: zvezdeCords[index].kord,
            link: value.link
        }
    });


    const linije = [
        { start: 1, end: 2 },
        { start: 1, end: 3 },
        { start: 3, end: 4 },
        { start: 3, end: 5 },
        { start: 3, end: 6 },
        { start: 5, end: 6 },
        { start: 6, end: 7 },
        { start: 6, end: 8 },
    ]

    return (
        
        <div className={`szvzdj ${className}`}>
            {zvezde.map((zvezda) => (
                <Zvezda key={zvezda.id} zvezda={zvezda} ime={zvezda.ime} zfunkcija={funkcija} />
            ))}
            {linije.map((linija) => (
                <Linija key={`l${linija.start}-${linija.end}`} start={zvezde[linija.start - 1].kord} end={zvezde[linija.end - 1].kord} />
            ))}
        </div>
    );
};

const Linija = ({ start, end }) => {
    const length = Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
    const angle = (Math.atan2(end.y - start.y, end.x - start.x) * 180) / Math.PI;

    return (
        <div style={{
            position: 'absolute',
            left: start.x + 15,
            top: start.y + 15,
            width: length + 1,
            height: 2,
            background: 'whitesmoke',
            transform: `rotate(${angle}deg)`,
            transformOrigin: 'top left',
            opacity: '20%',
            zIndex: 1
            // boxShadow: '0 0 10px white, 0 0 20px white, 0 0 30px white',
        }}
        />
    );
}

export default Sazvezdje;