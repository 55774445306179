let foc = 0;

export const handleDotPosition = (callback, focused, maxValue = undefined) => {
    var block;
    if(maxValue){
        foc = foc <= maxValue ? foc : maxValue;
    } 
    //console.log(foc, focused, maxValue);
    if (!focused) {
        block = document.querySelectorAll('.agenda-block-wrapper')[foc];
    } else {
        foc = focused.focused;
        block = document.querySelectorAll('.agenda-block-wrapper')[focused.focused];
    }

    const distanceToTop = block.offsetTop;
    const halfBlockSize = block.offsetHeight / 2;

    const distanceN = distanceToTop + halfBlockSize;
    //console.log(distanceN);
    callback(distanceN);
};

export const throttle = (callback, delay, ...options) => {
    let isThrottled = false;

    return function () {
        if (!isThrottled) {
            isThrottled = true;
            setTimeout(() => {
                callback();
                isThrottled = false;
            }, delay);
        }
    };
}