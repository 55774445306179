import { useEffect, useState } from 'react'

/**
 * @param {String} key ime u ključa u browser skladištu
 * @param {Boolean} initialValue 
 * @returns vrednost promenljive i funkciju da promeni tu vrednost
 */
export default function useStorageState(key, initialValue) {
    const storedValue = localStorage.getItem(key);
    const initial = storedValue ? JSON.parse(storedValue) : initialValue;
    const [value, setValue] = useState(initial);

    /**
     * Inicijalizuje i plus na svaku promenu vrednosti upisuje u localStorage
     */
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    const toggleValue = () => {
        setValue(prevValue => {
            const newValue = !prevValue;
            return newValue;
        });
    };

    return [value, toggleValue];
}
