import React from "react";
import { Link } from "react-router-dom";
import button from "./dugme.png";

const Okvir = ({ tekst, icon, buttonH, buttonB }) => {
  const icons = [
    "./ikonice/tehnologija.png",
    "./ikonice/kreativnost.png",
    "./ikonice/programiranje.png",
    "./ikonice/tim.png",
    "./ikonice/nagrade.png",
  ];
  return (
    <div className="okvir">
        {icon && <img src={require(`${icons[icon - 1]}`)} />}
        <p dangerouslySetInnerHTML={{ __html: tekst }}></p>
        {buttonH && (
          <Link to="http://localhost:3000/prijava">
            <img src={button} className="prijava-dugme" />
          </Link>
        )}
        {buttonB && (
          <Link to="http://localhost:3000/prijava">
            <img src={button} className="prijava-dugme" />
          </Link>
        )}
    </div>
  );
};

export default Okvir;
