import React, { useState, useEffect } from "react";
import Okvir from "./Okvir";
import strelica from "./Strelica.png";

const OBlockchainCard = () => {
  const blockchain = [
    "<b>FON Hakaton 2024 – Blockchain Challenge</b> pruža jedinstvenu priliku studentima da pokažu svoje veštine i kreativnost u svetu blockchain tehnologija. Učesnici će imati priliku da krozimplementaciju pametnih ugovora koristeći PyTeal i Solidity programske jezike i razvoj inovatnih NFT projekata predlože nove poslovne modele. Ovaj događaj je jedinstvena šansa za stvaranje novih ideja i interakciju sa stručnjacima iz Web3 sveta.",
    "Benefiti za sve učesnike FON Hakaton 2024 – Blockchain Challenge-a su: <ul> <li>Program mentorstva </li> <li>Mogućnost umrežavanja sa drugim studentima i celokupnim akademskim okruženjem</li> <li>Praktično iskustvo rada u blockchain tehnologijama</li> <li>Mogućnost razvoja i lansiranja novih rešenja zasnovanih na blockchain tehnologijama</li> <li>Novčane nagrade za najbolja tri tima</li> <li>Specijalne novčane i partnerske nagrade za najbolje nominovane projekte iz različitih kategorija</li>",
    "Vidimo se na FON Hakaton 2024 – Blockchain Challenge-u! Više o događaju možete saznati putem <a href='https://bc.elab.fon.bg.ac.rs/hackathon/' target='_blank'>linka </a>.    ",
    "Ne propusti priliku! <a href='/prijava'>Prijavi se</a> do 16. marta.",
  ];
  const [tekstBlockChain, setTekstBlockChain] = useState(blockchain[0]);
  const [buttonB, setButtonB] = useState(false);
//   window.localStorage.setItem("textB", tekstBlockChain);

  const handleTextChange = (forward) => {
    let index;
    if (forward) {
      index = blockchain.indexOf(tekstBlockChain);
      if (index === blockchain.length - 1) setTekstBlockChain(blockchain[0]);
      else setTekstBlockChain(blockchain[index + 1]);
    } else {
      index = blockchain.indexOf(tekstBlockChain);
      if (index === 0) setTekstBlockChain(blockchain[blockchain.length - 1]);
      else setTekstBlockChain(blockchain[index - 1]);
    }
  };

  useEffect(() => {
    setTekstBlockChain(blockchain[0]);
    // setTekstBlockChain(window.localStorage.getItem("textB"));
  }, []);

  // useEffect(() => {
  //   // window.localStorage.setItem("textB", tekstBlockChain);
  //   if (blockchain.indexOf(tekstBlockChain) === blockchain.length - 1)
  //     setButtonB(true);
  //   else setButtonB(false);
  // }, [tekstBlockChain]);

  return (
    <div className="card">
      <h1>O Blockchain Challenge-u</h1>

      <div className="card-buttons">
        <img
          src={strelica}
          className="druga"
          onClick={() => handleTextChange(false)}
        />
        <Okvir tekst={tekstBlockChain} buttonB={buttonB} />
        <img src={strelica} onClick={() => handleTextChange(true)} />
      </div>
    </div>
  );
};

export default OBlockchainCard;
