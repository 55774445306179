import FaqPitanje from "./components/FaqPitanje/FaqPitanje";

function FHGrupacija () {
    return (
        <>
            <FaqPitanje question={"Ko ima pravo učešća na FON Hakatonu?"} answer={"Pravo učešća na Hakatonu imaju svi stariji od 16 i mlađi od 26 godina kako iz Srbije tako i iz inostranstva, bez obzira na državljanstvo ili nivo formalnog obrazovanja."}></FaqPitanje>
            <FaqPitanje question={"Da li se učešće na takmičenju plaća?"} answer={"Ne, učešče je potpuno besplatno."}></FaqPitanje>
            <FaqPitanje question={"Koja je tema ovogodišnjeg FON Hakatona?"} answer={"Za sada je tema Hakatona tajna, ali će biti otkrivena na brifingu nakon otvaranja takmičenja."}></FaqPitanje>
            <FaqPitanje question={"Da li je moguća individualna prijava?"} answer={"Nije moguća individualna prijava. Šalje se timska prijava, a tim može imati tri ili četiri člana."}></FaqPitanje>
            <FaqPitanje question={"Da li postoji mentorska podrška tokom takmičenja?"} answer={"Naravno. Tokom razvijanja rešenja, u određenim terminima, takmičari će imati mogućnost konsultovanja sa mentorima koje predstavljaju stručnjaci sa Fakulteta organizacionih nauka i alumni članovi FONIS-a."}></FaqPitanje>
            <FaqPitanje question={"Koje tehnologije je dozvoljeno koristiti pri izradi rešenja?"} answer={"Takmičari mogu koristiti tehnologije po svom izboru."}></FaqPitanje>
            
            <FaqPitanje question={"Kada počinju prijave za FON Hakaton?"} answer={"Prijave za ovogodišnji FON Hakaton počinju od 2. marta."}></FaqPitanje>
            <FaqPitanje question={"Kada se zatvaraju prijave za FON Hakaton?"} answer={"Prijave za ovogodišnji FON Hakaton se zatvaraju 16. marta."}></FaqPitanje>
            <FaqPitanje question={"Da li postoje neke nagrade za najbolji tim?"} answer={"Prva tri najbolja tima osvajaju nagrade u vrednosti od 120.000, 80.000 i 50.000 dinara."}></FaqPitanje>
            <FaqPitanje question={"Kako izgleda proces izbora pobedničkog tima?"} answer={"Nakon što svi timovi prezentuju svoja rešenja 31. marta, stručni žiri, koji prati celokupnu prezentaciju rešenja, donosi odluku o tome kome će se dodeliti nagrade."}></FaqPitanje>
        
        </>
    );
}

export default FHGrupacija;