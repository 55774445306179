import React , { useContext }from 'react';
import './Pocetna.scss'
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import HeaderSazvezdje from '../Header/HeaderSazvezdje';

import { SwitchContextProvider } from '../Switch/context/SwitchProvider';

const Pocetna = () => {

    
  const [value, toggleValue] = useContext(SwitchContextProvider);

    const [slika, setSlika] = useState(require('./img/Naslov.png'))
    const navigate = useNavigate();
    const updateSize = () => {
        setSlika(window.matchMedia("max-width: 795px").matches ? require('./img/Naslov.png') : require('./img/NaslovMali.png'));
    }
    useEffect(() => {
        updateSize(); // Initial call to set the initial state

        // Add event listener
        window.addEventListener('resize', updateSize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);
    return (
        <div className="pocetna">
            <HeaderSazvezdje imasv={false} bezlogoa={true} />
            <div className='vrh'>
                <img src={require('./img/Logo.png')} className='logo' />
                <img src={slika} className='naslov' />
            </div>
            <div className='tajmidugm'>
                <div className='tajmer'>
                    {/* dani i meseci pocinju od nule tako da je ovo 16. mart u ponoc */}
                    {/* <Countdown date={new Date(2024, 2, 16, 23, 59)} className='vreme' zeroPadDays={2} /> */}
                    <p className='opis' style={{fontSize:"2rem"}}>PRIJAVE SU ZATVORENE</p>
                    <div className='portal'>
                        <img src={require("./img/Trapez.png")} className='trapez' alt='' />
                        <img src={require('./img/Eljips1.png')} className='elips1' alt='' />
                        <img src={require('./img/Eljips2.png')} className='elips2' alt='' />
                    </div>
                    {/* <div className='opis'>VREME DO ZATVARANJA PRIJAVA</div> */}
                </div>
                {/* <div className='dugmici'>
                    <div className='fonhak'>
                        <div className='tekst'>FON HAKATON</div>
                        <div className='dugme' onClick={() => {
                            if(value)
                                toggleValue(false)
                            navigate('/prijava')}}
                            style={{cursor:"pointer"}}
                            >PRIJAVI SE</div>
                    </div>
                    <div className='blockch' >
                        <div className='tekst'>BLOCKCHAIN CHALLENGE</div>
                        <div className='dugme' onClick={() => {
                            if(!value)
                                toggleValue('bc-state')
                            navigate('/prijava')
                            }}  style={{cursor:"pointer"}}>PRIJAVI SE</div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Pocetna;