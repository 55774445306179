import './Partneri.scss'
import { Stars } from '../Background/Stars';
import PartneriMobile from '../Partneri/PartneriMobile';
import PartneriFullScreen from '../Partneri/PartneriFullScreen';
import { Fragment, useEffect, useState } from 'react';
import matchers from '@testing-library/jest-dom/matchers';
import Media from 'react-media';
import HeaderSazvezdje from '../Header/HeaderSazvezdje';



const Partneri = () => {
    
    //mobilni slajdovanje
  const imagesStrat = require.context('./images/strateski', true);
  const parStratSlike = imagesStrat.keys().map(image => imagesStrat(image));
    // var parStratSlike = 

  const imagesNat = require.context('./images/naturalni', true);
  const parNatSlike = imagesNat.keys().map(image => imagesNat(image));
    
    var parMedSlike = 
    [
    ];
    
    var [parNatBrojac,setParNatBrojac] = useState(0);
    var [parStratBrojac,setParStratBrojac] = useState(0);
    var [parMedBrojac,setParMedBrojac] = useState(0);
    var [parNatSlika, setParNatSlika] = useState(parNatSlike[parNatBrojac]);

    var [parStratSlika, setParStratSlika] = useState(parStratSlike[parStratBrojac]);
    var [parMedSlika, setParMedSlika] = useState(parMedSlike[parMedBrojac]);
    
    
    const navParGallery = (parDirection, parImages, setParSlika) => {
      let natIndex = parNatBrojac;
      let stratIndex = parStratBrojac;
      let medIndex = parMedBrojac;
    
      if (parDirection === 'left-nat') {
        natIndex = (parNatBrojac - 1 + parImages.length) % parImages.length;
      } else if (parDirection === 'right-nat') {
        natIndex = (parNatBrojac + 1) % parImages.length;
      }
      setParNatBrojac(natIndex);
      setParSlika(parImages[natIndex]);
    
      if (parDirection === 'left-strat') {
        stratIndex = (parStratBrojac - 1 + parImages.length) % parImages.length;
      } else if (parDirection === 'right-strat') {
        stratIndex = (parStratBrojac + 1) % parImages.length;
      }
      setParStratBrojac(stratIndex);
      setParSlika(parImages[stratIndex]);
    
    
      if (parDirection === 'left-med') {
        medIndex = (parMedBrojac - 1 + parImages.length) % parImages.length;
      } else if (parDirection === 'right-med') {
        medIndex = (parMedBrojac + 1) % parImages.length;
      }
      setParMedBrojac(medIndex);
      setParSlika(parImages[medIndex]);
    };
    
    // Naturalni dugmici
    const parNatLevo = () => {
      navParGallery('left-nat', parNatSlike, setParNatSlika);
    };
    
    const parNatDesno = () => {
      navParGallery('right-nat', parNatSlike, setParNatSlika);
    };
    
    // Strateski dugmici
    const parStratLevo = () => {
      navParGallery('left-strat', parStratSlike, setParStratSlika);
    };
    
    const parStratDesno = () => {
      navParGallery('right-strat', parStratSlike, setParStratSlika);
    };
    
    // Medijski dugmici
    const parMedLevo = () => {
      navParGallery('left-med', parMedSlike, setParMedSlika);
    };
    
    const parMedDesno = () => {
      navParGallery('right-med', parMedSlike, setParMedSlika);
    };

    useEffect(() => {
    },[])
    
    return (
    <>
        <HeaderSazvezdje imasv={false}  bezlogoa={false}/>
        <Media queries = {{
                parmobilna: "(max-width: 849px)",
                parfull_screen: "(min-width: 850px)"
            }}>
            {matchers => (
                <Fragment>
                    {matchers.parfull_screen &&  <PartneriFullScreen />}
                    {matchers.parmobilna &&  
                    <PartneriMobile 
                    parNatLevo={()=>{parNatLevo(parNatSlike)}} 
                    parNatDesno = {()=>{parNatDesno(parNatSlike)}} 
                    parNatSlika={parNatSlike[parNatBrojac]}
                    parStratLevo={()=>{parStratLevo(parStratSlike)}} 
                    parStratDesno = {()=>{parStratDesno(parStratSlike)}} 
                    parStratSlika={parStratSlike[parStratBrojac]}
                    parMedLevo={()=>{parMedLevo(parMedSlike)}} 
                    parMedDesno = {()=>{parMedDesno(parMedSlike)}} 
                    parMedSlika={parMedSlike[parMedBrojac]}
                    />}
                </Fragment>
            )}
        </Media>    
    </>
    )
}

export default Partneri;
