import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SwitchProvider from './components/Switch/context/SwitchProvider';
import HeaderProvider from './components/Header/context/HeaderProvider';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  // <React.StrictMode>
 <Router>
 <SwitchProvider>
  <HeaderProvider>
   <App />
 </HeaderProvider>
 </SwitchProvider>
 </Router>

  // </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
